import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Storage } from "aws-amplify";
import classnames from "classnames";
import {
  FAVOURITES_PLAYLIST_DEFAULT_DESC,
  FAVOURITES_PLAYLIST_TITLE,
  NEW_DAYS,
  GQL_LIMIT,
} from "constants.js";
import {
  CREATE_PLAYLIST,
  CREATE_PLAYLIST_VIDEO,
  DELETE_PLAYLIST_VIDEO,
} from "graphql/custom/mutations";
import {
  LIST_PLAYLISTS_ONLY,
  LIST_PLAYLIST_VIDEOS_BY_VIDEO,
  LIST_PLAYLIST_VIDEOS_BY_PLAYLIST,
} from "graphql/custom/queries";
import moment from "moment";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useMutation, useQuery } from "react-apollo";
import { Overlay, Popover } from "react-bootstrap";
import { Link, useHistory, useRouteMatch } from "react-router-dom";
import { useToggle } from "react-use";
import { useGlobal } from "reactn";
import { ContextMenu } from "./ContextMenu";

const Video = props => {
  const {
    info: { id, title, approved, owner, mp4, thumb, approvedAt, videoSlugId: slug },
  } = props;

  const [showCtxMenu, toggleCtxMenu] = useToggle();
  const [isFavourite, toggleFavourite] = useToggle();
  const [isAdmin] = useGlobal("isAdmin");
  const [auth] = useGlobal("auth");
  const liEl = useRef(null);
  const ctxBtnEl = useRef(null);

  let { url } = useRouteMatch();
  let history = useHistory();

  const [thumbUrl, setThumbUrl] = useState(null);
  const playlists = useQuery(LIST_PLAYLISTS_ONLY, {
    variables: { limit: GQL_LIMIT },
  });

  const playlistVideos = useQuery(LIST_PLAYLIST_VIDEOS_BY_VIDEO, {
    variables: { videoID: id, limit: GQL_LIMIT },
  });
  const [createPlaylist] = useMutation(CREATE_PLAYLIST, {
    refetchQueries: ["ListPlaylists"],
  });

  const [createPlaylistVideo] = useMutation(CREATE_PLAYLIST_VIDEO);
  const [deletePlaylistVideo] = useMutation(DELETE_PLAYLIST_VIDEO);
  useEffect(() => {
    if (thumb) {
      Storage.get(thumb).then(setThumbUrl);
    }
    if (playlistVideos.loading || playlistVideos.error) {
      return;
    }

    toggleFavourite(
      playlistVideos.data.playlistVideosByVideo.items.some(pv => {
        return (
          pv.playlist != null && pv.playlist.title === FAVOURITES_PLAYLIST_TITLE
        );
      })
    );
  }, [
    playlistVideos.data,
    playlistVideos.error,
    playlistVideos.loading,
    thumb,
    toggleFavourite,
  ]);

  const updateFavourite = useCallback(
    async e => {
      if (playlists.loading) {
        return;
      }
      let targetPlaylist;
      const favouritePlaylistVideo = playlistVideos.data.playlistVideosByVideo.items.find(
        pv => {
          return (
            pv.playlist != null &&
            pv.playlist.title === FAVOURITES_PLAYLIST_TITLE
          );
        }
      );
      const favouritePlaylist = playlists.data.listPlaylists.items.find(p => {
        return p.title === FAVOURITES_PLAYLIST_TITLE;
      });
      if (!favouritePlaylist) {
        const {
          data: { createPlaylist: createdPlaylist },
        } = await createPlaylist({
          variables: {
            input: {
              title: FAVOURITES_PLAYLIST_TITLE,
              description: FAVOURITES_PLAYLIST_DEFAULT_DESC,
            },
          },
        });
        targetPlaylist = createdPlaylist;
      } else {
        targetPlaylist = favouritePlaylist;
      }

      if (isFavourite) {
        if (!favouritePlaylistVideo) {
          console.error("missing favouritePlaylistVideo");
          return;
        }
        await deletePlaylistVideo({
          variables: { input: { id: favouritePlaylistVideo.id } },
          awaitRefetchQueries: false,
          refetchQueries: [
            {
              query: LIST_PLAYLIST_VIDEOS_BY_PLAYLIST,
              variables: { playlistID: targetPlaylist.id, limit: GQL_LIMIT },
            },
            {
              query: LIST_PLAYLIST_VIDEOS_BY_VIDEO,
              variables: { videoID: id, limit: GQL_LIMIT },
            },
          ],
        });
      } else {
        if (!targetPlaylist) {
          console.error("targetPlaylistId is missing");
          return;
        }
        await createPlaylistVideo({
          variables: { input: { playlistID: targetPlaylist.id, videoID: id } },
          awaitRefetchQueries: false,
          refetchQueries: [
            {
              query: LIST_PLAYLIST_VIDEOS_BY_PLAYLIST,
              variables: { playlistID: targetPlaylist.id, limit: GQL_LIMIT },
            },
            {
              query: LIST_PLAYLIST_VIDEOS_BY_VIDEO,
              variables: { videoID: id, limit: GQL_LIMIT },
            },
          ],
        });
      }

      toggleFavourite();
    },
    [
      createPlaylist,
      createPlaylistVideo,
      deletePlaylistVideo,
      id,
      isFavourite,
      playlistVideos.data,
      playlists.data,
      playlists.loading,
      toggleFavourite,
    ]
  );

  const recent = moment().subtract(NEW_DAYS, "days");
  const videoIsNew = approvedAt != null && recent.isSameOrBefore(approvedAt);

  return (
    <li
      ref={liEl}
      className={classnames({
        "video-item": true,
        approved,
        forcedVisible: showCtxMenu,
      })}
    >
      <div
        className={classnames("video-item-container", { "is-new": videoIsNew })}
      >
        {approvedAt === null && <div className="video-item-waiting-approval">Awaiting approval</div> }
        {videoIsNew && <div className="video-item-new">NEW!</div>}
        {thumbUrl ? (
          <Link
            to={{ pathname: `${url}/${slug}`, state: { fromDashboard: true } }}
          >
            <div
              style={{
                backgroundImage: `url(${thumbUrl})`,
              }}
              className="video-item-thumb"
            ></div>
          </Link>
        ) : (
          <div className="video-item-thumb">Missing thumb...</div>
        )}

        {(isAdmin || (auth && auth.sub === owner)) && (
          <button className="video-item-edit">
            <Link to={`${url}/${slug}/edit`}>
              <FontAwesomeIcon icon="pencil-alt" />
            </Link>
          </button>
        )}

        {approvedAt != null &&
        <FontAwesomeIcon
          icon="star"
          onClick={updateFavourite}
          className={classnames("video-item-favourite", { isFavourite })}
        />}

        <button
          ref={ctxBtnEl}
          onClick={toggleCtxMenu}
          className="video-item-ctx-button"
        >
          <FontAwesomeIcon icon="ellipsis-v" />
        </button>

        <Overlay
          target={ctxBtnEl}
          show={showCtxMenu}
          placement="right"
          rootClose={true}
          onHide={() => toggleCtxMenu(false)}
        >
          {({ show, ...props }) => {
            return (
              <Popover {...props} className="video-item-ctx">
                <Popover.Content>
                  <ContextMenu videoId={id} slug={slug} playlistVideos={playlistVideos} />
                </Popover.Content>
              </Popover>
            );
          }}
        </Overlay>

        <div
          className="video-item-caption"
          onClick={() => mp4 && history.push(`${url}/${slug}`)}
        >
          <h5>{title}</h5>
        </div>
      </div>
    </li>
  );
};

export default Video;
