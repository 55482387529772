import { RequireNewPassword } from "aws-amplify-react";
import React from "react";
import { Button, Form } from "react-bootstrap";

export class CustomRequireNewPassword extends RequireNewPassword {
  constructor(props) {
    super(props);

    this._validAuthStates = ["requireNewPassword"];
  }

  showComponent(theme) {
    const { hide } = this.props;
    if (hide && hide.includes(CustomRequireNewPassword)) {
      return null;
    }

    const user = this.props.authData;
    const { requiredAttributes } = user.challengeParam;
    return (
      <div className="login">
        <div className="blurred-bg"></div>
        <div className="login-form-container">
          <Form className="form-signin" onSubmit={this.signIn}>
            <header className="text-center mb-4">
              <div className="mb-4 login-logo"></div>
              <h1 className="h1 mb-3">Change Password</h1>
            </header>
            <div>
              <Form.Group controlId="password">
                <Form.Control
                  autoFocus
                  placeholder={"New Password"}
                  theme={theme}
                  key="password"
                  name="password"
                  type="password"
                  onChange={this.handleInputChange}
                />
              </Form.Group>

              {requiredAttributes.map(attribute => (
                <Form.Group controlId={attribute}>
                  <Form.Control
                    placeholder={convertToPlaceholder(attribute)}
                    theme={theme}
                    key={attribute}
                    name={attribute}
                    type="text"
                    onChange={this.handleInputChange}
                  />
                </Form.Group>
              ))}
            </div>
            <footer>
              <Form.Row>
                <Button block size="lg" onClick={this.change}>
                  Change
                </Button>
              </Form.Row>

              <Form.Row>
                <Button
                  variant="link"
                  onClick={() => this.changeState("signIn")}
                >
                  Back to Sign In
                </Button>
              </Form.Row>
            </footer>
          </Form>
        </div>
      </div>
    );
  }
}

function convertToPlaceholder(str) {
  return str
    .split("_")
    .map(part => part.charAt(0).toUpperCase() + part.substr(1).toLowerCase())
    .join(" ");
}
