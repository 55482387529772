import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import confirm from "components/ConfirmationDialog";
import {
  CREATE_CATEGORY,
  UPDATE_CATEGORY_GROUP,
} from "graphql/custom/mutations";
import React, { useCallback } from "react";
import { useMutation } from "react-apollo";
import {
  Button,
  ButtonGroup,
  Col,
  Form,
  FormControl,
  FormGroup,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import { useDebouncedCallback } from "use-debounce";

export const CategoryGroupForm = props => {
  const { onDelete, onMove, group, children } = props;
  const [updateCategoryGroup] = useMutation(UPDATE_CATEGORY_GROUP);
  const [createCategory] = useMutation(CREATE_CATEGORY, {
    refetchQueries: ["ListCategoryGroups"],
  });
  const onUpdateCategoryGroup = useCallback(
    async (key, value) =>
      await updateCategoryGroup({
        variables: { input: { id: group.id, [key]: value } },
      }),
    [group.id, updateCategoryGroup]
  );
  const onCreateCategory = useCallback(
    group => async () =>
      await createCategory({
        variables: {
          input: {
            categoryGroupId: group.id,
            order: group.categories.length,
          },
        },
      }),
    [createCategory]
  );
  const [updateCallback] = useDebouncedCallback(onUpdateCategoryGroup, 1000);
  return (
    <>
      <Form onChange={e => updateCallback(e.target.name, e.target.value)}>
        <FormGroup as={Row} controlId={group.id}>
          <Col sm={1}>
            <OverlayTrigger
              overlay={
                <Tooltip>
                  Delete group <strong>{group.label}</strong>
                </Tooltip>
              }
            >
              <Button
                variant="link"
                onClick={e => {
                  confirm(
                    "Are you sure you want to delete group?"
                  ).then(onDelete, () => {});
                }}
              >
                <FontAwesomeIcon icon="trash-alt" />
              </Button>
            </OverlayTrigger>
          </Col>
          <Form.Label
            column
            sm={2}
            className={classNames({ "bg-warning": group.order == null })}
          >
            Group <em>{group.order + 1}</em>
          </Form.Label>
          <Col sm={8}>
            <FormControl size="sm" name="label" defaultValue={group.label} />
          </Col>
          <Col sm={1}>
            <ButtonGroup>
              <Button
                variant="link"
                onClick={() => {
                  onMove("up");
                }}
              >
                <FontAwesomeIcon icon="arrow-up" />
              </Button>
              <Button
                variant="link"
                onClick={() => {
                  onMove("down");
                }}
              >
                <FontAwesomeIcon icon="arrow-down" />
              </Button>
            </ButtonGroup>
          </Col>
        </FormGroup>
      </Form>
      {children}
      <OverlayTrigger
        overlay={
          <Tooltip>
            Create new category in group <strong>{group.label}</strong>
          </Tooltip>
        }
      >
        <Button variant="link" onClick={onCreateCategory(group)}>
          <FontAwesomeIcon icon="plus-circle" color="green" />
        </Button>
      </OverlayTrigger>
    </>
  );
};
