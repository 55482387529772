import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Auth } from "aws-amplify";
import Filter from "components/Filter";
import Searchbar from "components/Searchbar";
import SortOrder from "components/SortOrder";
import PropTypes from "prop-types";
import React, { useRef, useState } from "react";
import { Overlay, OverlayTrigger, Popover, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import { resetGlobal, useGlobal } from "reactn";
import PlaylistSelector from "./PlaylistSelector";
import useLocalStorage from "@rehooks/local-storage";

const Sidebar = ({
  searchPhrase,
  setSearchPhrase,
  filterOptions,
  setFilterOptions,
  sortOrder,
  setSortOrder,
}) => {
  const [showFilterSelector, setShowFilterSelector] = useState(false);
  const [showSortOrderSelector, setShowSortOrderSelector] = useState(false);
  const [showPlaylists, setShowPlaylists] = useState(false);
  const showOrderTarget = useRef(null);
  const playlistsEl = useRef(null);
  const [isAdmin] = useGlobal("isAdmin");
  const [termsAccepted] = useLocalStorage("terms-accepted");

  const logout = async () => {
    await Auth.signOut();
    resetGlobal();
    localStorage.clear();
    sessionStorage.clear();
  };

  const togglePlaylists = () => {
    hideSortOrderSelector();
    setShowFilterSelector(false);
    setShowPlaylists(!showPlaylists);
  };

  const toggleShowFilterSelector = () => {
    setShowFilterSelector(!showFilterSelector);
    hideSortOrderSelector();
  };

  const toggleShowSortOrderSelector = () => {
    setShowSortOrderSelector(!showSortOrderSelector);
    setShowFilterSelector(false);
  };

  const hideSortOrderSelector = () => setShowSortOrderSelector(false);

  const clearSearchAndFilter = () => {
    setSearchPhrase("");
    setFilterOptions(null);
    setShowFilterSelector(false);
    hideSortOrderSelector();
  };

  if(!termsAccepted) {
    return <div className="sidebar"></div>
  }
  return (
    <div className="sidebar">
      {showFilterSelector && (
        <Filter {...{ filterOptions, setFilterOptions }} />
      )}
      <div className="archive-search-bar">
        <Searchbar {...{ searchPhrase, setSearchPhrase }} />

        <OverlayTrigger placement="right" overlay={<Tooltip>Filter</Tooltip>}>
          <button
            onClick={toggleShowFilterSelector}
            className="action-cta btn-lg"
          >
            <FontAwesomeIcon icon={showFilterSelector ? "check" : "filter"} />
          </button>
        </OverlayTrigger>

        <OverlayTrigger
          placement="right"
          overlay={<Tooltip>Clear search and filter</Tooltip>}
        >
          <button onClick={clearSearchAndFilter} className="action-cta btn-sm">
            <FontAwesomeIcon icon="undo" />
          </button>
        </OverlayTrigger>

        <OverlayTrigger placement="right" overlay={<Tooltip>Sort</Tooltip>}>
          <button
            ref={showOrderTarget}
            onClick={toggleShowSortOrderSelector}
            className="action-cta btn-lg"
          >
            <FontAwesomeIcon icon="sort" size="lg" />
          </button>
        </OverlayTrigger>
        <Overlay
          target={showOrderTarget}
          show={showSortOrderSelector}
          placement="right"
        >
          {({ show, ...props }) => {
            return (
              <Popover {...props} className="popup sort-box">
                <Popover.Content>
                  <SortOrder
                    {...{
                      sortOrder,
                      setSortOrder,
                      onChange: () => setShowSortOrderSelector(false),
                    }}
                  />
                </Popover.Content>
              </Popover>
            );
          }}
        </Overlay>
      </div>
      <div className="archive-actions">
        <OverlayTrigger
          placement="right"
          overlay={<Tooltip>Playlists</Tooltip>}
        >
          <button
            ref={playlistsEl}
            onClick={togglePlaylists}
            className="action-cta btn-lg playlist"
          >
            <FontAwesomeIcon icon="list" />
          </button>
        </OverlayTrigger>

        <Overlay
          target={playlistsEl}
          show={showPlaylists}
          placement="right"
          rootClose={true}
          onHide={() => togglePlaylists(false)}
        >
          {({ show, ...props }) => {
            return (
              <Popover {...props} className="popup">
                <Popover.Content>
                  <PlaylistSelector toggle={togglePlaylists} />
                </Popover.Content>
              </Popover>
            );
          }}
        </Overlay>

        <Link to="/v/new">
          <OverlayTrigger
            placement="right"
            overlay={<Tooltip>Add new video to library</Tooltip>}
          >
            <button className="action-cta btn-lg add">
              <FontAwesomeIcon icon="plus" />
            </button>
          </OverlayTrigger>
        </Link>
        {isAdmin && (
          <Link to="/administration">
            <OverlayTrigger
              placement="right"
              overlay={<Tooltip>Manage video categories and users</Tooltip>}
            >
              <button className="action-cta btn-lg">
                <FontAwesomeIcon icon="cog" />
              </button>
            </OverlayTrigger>
          </Link>
        )}
        <OverlayTrigger placement="right" overlay={<Tooltip>Logout</Tooltip>}>
          <button className="action-cta btn-lg" onClick={logout}>
            <FontAwesomeIcon icon="sign-out-alt" />
          </button>
        </OverlayTrigger>
      </div>
      <footer>
        <Link to="/terms">Terms</Link>
      </footer>
    </div>
  );
};
Sidebar.propTypes = {
  searchPhrase: PropTypes.string,
  setSearchPhrase: PropTypes.func.isRequired,
  filterOptions: PropTypes.array,
  setFilterOptions: PropTypes.func.isRequired,
  sortOrder: PropTypes.object,
  setSortOrder: PropTypes.func.isRequired,
};
export default Sidebar;
