import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button, Col, Container, Row, Tab, Tabs } from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";
import Tags from "./components/Tags";
import Users from "./components/Users";

const AdministrationPage = props => {
  return (
    <Container className="administration-container">
      <Row className="top-back-nav">
        <Col className="backButton">
          <Link to="/v">
            <Button type="button" variant="outline-light">
              <FontAwesomeIcon icon="arrow-left" />
              &nbsp;&nbsp;Back to video library
            </Button>
          </Link>
        </Col>
      </Row>
      <Row>
        <Col>
          <Tabs defaultActiveKey="tags">
            <Tab eventKey="tags" title="Video tags">
              <Tags />
            </Tab>
            <Tab eventKey="user" title="Users">
              <Users />
            </Tab>
          </Tabs>
        </Col>
      </Row>
    </Container>
  );
};

export default withRouter(AdministrationPage);
