import React from "react";
export const UserStatus = ({ status, email }) => {
  switch (status) {
    case "UNCONFIRMED":
      return (
        <>
          User <strong>{email}</strong> has been created but not confirmed.
        </>
      );
    case "CONFIRMED":
      return (
        <>
          User <strong>{email}</strong> has been confirmed.
        </>
      );
    case "ARCHIVED":
      return (
        <>
          User <strong>{email}</strong> is no longer active.
        </>
      );
    case "COMPROMISED":
      return (
        <>
          User <strong>{email}</strong> is disabled due to a potential security
          threat.
        </>
      );
    case "UNKNOWN":
      return <>User status is not known.</>;
    case "RESET_REQUIRED":
      return (
        <>
          User <strong>{email}</strong> is confirmed, but the user must request
          a code and reset his or her password before he or she can sign in.
        </>
      );
    case "FORCE_CHANGE_PASSWORD":
      return (
        <>
          User <strong>{email}</strong> is confirmed and the user can sign in
          using a temporary password, but on first sign-in, the user must change
          his or her password to a new value before doing anything else.
        </>
      );
    default:
      return (
        <>
          Unknown status <strong>{status}</strong> for <strong>{email}</strong>
        </>
      );
  }
};
