import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { FAVOURITES_PLAYLIST_TITLE, GQL_LIMIT } from "constants.js";
import { Formik } from "formik";
import {
  CREATE_PLAYLIST,
  CREATE_PLAYLIST_VIDEO,
  DELETE_PLAYLIST_VIDEO,
} from "graphql/custom/mutations";
import {
  LIST_PLAYLISTS_ONLY,
  LIST_PLAYLIST_VIDEOS_BY_PLAYLIST,
  LIST_PLAYLIST_VIDEOS_BY_VIDEO,
} from "graphql/custom/queries";
import React from "react";
import { useMutation, useQuery } from "react-apollo";
import {
  Button,
  ButtonGroup,
  Form,
  FormCheck,
  FormControl,
  ListGroup,
  ListGroupItem,
} from "react-bootstrap";
import { useToggle } from "react-use";
export const PlaylistsSelector = ({ videoId }) => {
  const [inCreateMode, toggleCreateMode] = useToggle();

  const [createPlaylist] = useMutation(CREATE_PLAYLIST, {
    refetchQueries: [
      { query: LIST_PLAYLISTS_ONLY, variables: { limit: GQL_LIMIT } },
    ],
  });
  const playlistVideos = useQuery(LIST_PLAYLIST_VIDEOS_BY_VIDEO, {
    variables: { videoID: videoId, limit: GQL_LIMIT },
  });
  const playlists = useQuery(LIST_PLAYLISTS_ONLY, {
    variables: { limit: GQL_LIMIT },
  });
  const [createPlaylistVideo] = useMutation(CREATE_PLAYLIST_VIDEO, {
    refetchQueries: [
      {
        query: LIST_PLAYLIST_VIDEOS_BY_VIDEO,
        variables: { videoID: videoId, limit: GQL_LIMIT },
      },
    ],
  });
  const [deletePlaylistVideo] = useMutation(DELETE_PLAYLIST_VIDEO, {
    refetchQueries: [
      {
        query: LIST_PLAYLIST_VIDEOS_BY_VIDEO,
        variables: { videoID: videoId, limit: GQL_LIMIT },
      },
    ],
  });

  const togglePlaylist = (playlist, playlistVideoID) => async e => {
    const checked = e.target.checked;
    if (checked) {
      await createPlaylistVideo({
        variables: {
          input: {
            videoID: videoId,
            playlistID: playlist.id,
          },
        },
        refetchQueries: [
          {
            query: LIST_PLAYLIST_VIDEOS_BY_VIDEO,
            variables: { videoID: videoId, limit: GQL_LIMIT },
          },
          {
            query: LIST_PLAYLIST_VIDEOS_BY_PLAYLIST,
            variables: { playlistID: playlist.id, limit: GQL_LIMIT },
          },
        ],
      });
    } else {
      await deletePlaylistVideo({
        variables: {
          input: {
            id: playlistVideoID,
          },
        },
        refetchQueries: [
          {
            query: LIST_PLAYLIST_VIDEOS_BY_VIDEO,
            variables: { videoID: videoId, limit: GQL_LIMIT },
          },
          {
            query: LIST_PLAYLIST_VIDEOS_BY_PLAYLIST,
            variables: { playlistID: playlist.id, limit: GQL_LIMIT },
          },
        ],
      });
    }
  };
  if (playlistVideos.loading || playlists.loading) {
    return "Loading...";
  }
  if (playlistVideos.error) {
    console.warn(playlistVideos.error.message);
  }
  if (playlists.error) {
    console.warn(playlists.error.message);
  }
  const activePlaylists = playlistVideos.data.playlistVideosByVideo.items.reduce(
    (acc, playlistVideo) => {
      if (!playlistVideo.playlist) {
        console.warn("Playlist missing", playlistVideo);
        return acc;
      }
      return { ...acc, [playlistVideo.playlist.id]: playlistVideo };
    },
    {}
  );

  return (
    <ListGroup variant="flush" className="video-playlist-selector">
      {playlists.data.listPlaylists.items
        .sort((a, b) => {
          if (a.title === FAVOURITES_PLAYLIST_TITLE) {
            return -1;
          }
          if (b.title === FAVOURITES_PLAYLIST_TITLE) {
            return 1;
          }
          return (a.title || "").localeCompare(b.title || "");
        })
        .map(playlist => (
          <ListGroupItem
            className={classNames("video-playlist-entry", {
              selected: playlist.id in activePlaylists,
            })}
            key={`playlist-${playlist.id}`}
          >
            <FormCheck
              label={
                <span
                  className={classNames({
                    "favourite-list-entry":
                      playlist.title === FAVOURITES_PLAYLIST_TITLE,
                  })}
                >
                  {playlist.title === FAVOURITES_PLAYLIST_TITLE && (
                    <FontAwesomeIcon icon="star" />
                  )}
                  {playlist.title}
                </span>
              }
              id={`playlist-check-${playlist.id}`}
              type="checkbox"
              aria-label={`enable playlist ${playlist.title}`}
              defaultChecked={playlist.id in activePlaylists}
              onChange={togglePlaylist(
                playlist,
                playlist.id in activePlaylists
                  ? activePlaylists[playlist.id].id
                  : null
              )}
            ></FormCheck>
          </ListGroupItem>
        ))}
      {inCreateMode ? (
        <Formik
          initialValues={{ title: "" }}
          onReset={() => toggleCreateMode(false)}
          onSubmit={async ({ title }) => {
            toggleCreateMode(false);
            await createPlaylist({
              variables: { input: { title } },
            });
          }}
        >
          {({
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            handleReset,
            isSubmitting,
          }) => (
            <Form
              onSubmit={handleSubmit}
              onReset={handleReset}
              className="d-flex flex-column"
            >
              <FormControl
                size="sm"
                name="title"
                placeholder="playlist name"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.title}
              ></FormControl>
              <ButtonGroup size="sm">
                <Button size="sm" type="reset">
                  Cancel
                </Button>
                <Button size="sm" type="submit" disabled={isSubmitting}>
                  Save
                </Button>
              </ButtonGroup>
            </Form>
          )}
        </Formik>
      ) : (
        <ListGroupItem
          variant="primary"
          action
          onClick={() => toggleCreateMode(true)}
          className="btn-add-playlist"
        >
          Create new playlist
        </ListGroupItem>
      )}
    </ListGroup>
  );
};
