import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import confirm from "components/ConfirmationDialog";
import React, { useCallback } from "react";
import {
  Button,
  Col,
  Form,
  FormControl,
  FormGroup,
  OverlayTrigger,
  Tooltip,
  ButtonGroup,
} from "react-bootstrap";
import { useDebouncedCallback } from "use-debounce";
import classNames from "classnames";
import { useMutation } from "react-apollo";
import { UPDATE_CATEGORY } from "graphql/custom/mutations";

export const CategoryForm = props => {
  const [updateCategory] = useMutation(UPDATE_CATEGORY);
  const { onDelete, onMove, category } = props;

  const onUpdateCategory = useCallback(
    async (key, value) =>
      await updateCategory({
        variables: { input: { id: category.id, [key]: value } },
      }),
    [category.id, updateCategory]
  );
  const [updateCallback] = useDebouncedCallback(onUpdateCategory, 1000);
  return (
    <Form onChange={e => updateCallback(e.target.name, e.target.value)}>
      <FormGroup as={Form.Row}>
        <Col sm={1}>
          <OverlayTrigger
            overlay={
              <Tooltip>
                Delete category <strong>{category.label}</strong>
              </Tooltip>
            }
          >
            <Button
              variant="link"
              onClick={() =>
                confirm(
                  "Are you sure you want to delete category?"
                ).then(onDelete, () => {})
              }
            >
              <FontAwesomeIcon icon="trash-alt" />
            </Button>
          </OverlayTrigger>
        </Col>
        <Form.Label
          column
          sm={2}
          className={classNames({ "bg-warning": category.order == null })}
        >
          Category <em>{category.order + 1}</em>
        </Form.Label>
        <Col sm={3}>
          <FormControl
            size="sm"
            id={`label-${category.id}`}
            name="label"
            defaultValue={category.label}
          />
        </Col>
        <Col sm={5}>
          <FormControl
            size="sm"
            id={`href-${category.id}`}
            name="href"
            defaultValue={category.href}
          />
        </Col>
        <Col sm={1}>
          <ButtonGroup>
            <Button
              variant="link"
              onClick={() => {
                onMove("up");
              }}
            >
              <FontAwesomeIcon icon="arrow-up" />
            </Button>
            <Button
              variant="link"
              onClick={() => {
                onMove("down");
              }}
            >
              <FontAwesomeIcon icon="arrow-down" />
            </Button>
          </ButtonGroup>
        </Col>
      </FormGroup>
    </Form>
  );
};
