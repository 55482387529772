import { useLocalStorage } from "@rehooks/local-storage";
import { Auth } from "aws-amplify";
import React, { useState } from "react";
import { Button, Card, Fade, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useGlobal } from "reactn";
export const AuthTerms = () => {
  const [auth] = useGlobal("auth");
  const [acceptedToggle, setAcceptedToggle] = useState(false);
  const [termsAccepted, setTermsAccepted] = useLocalStorage("terms-accepted");

  if (!auth) {
    return "";
  }

  const confirm = async () => {
    let user = await Auth.currentAuthenticatedUser();

    await Auth.updateUserAttributes(user, {
      "custom:terms_accepted": "true",
    });
    await Auth.currentAuthenticatedUser({ bypassCache: true });
    setTermsAccepted(true);
  };
  return (
    <Fade appear={true} in={!termsAccepted}>
      <footer className="termsAndConditions">
        <Card bg="dark" text="light" className="terms-card">
          <Card.Body>
            <Card.Title>Terms and conditions</Card.Title>
            <div className="justify-content-between align-items-center">
              <div>
                Please read through{" "}
                <Link to="/terms" target="_blank">
                  our terms and conditions
                </Link>{" "}
                and accept it.
              </div>{" "}
              <div>
                <Form.Group controlId="accepted">
                  <Form.Check
                    type="switch"
                    label="Accept Terms & Conditions"
                    checked={acceptedToggle}
                    onChange={e => {
                      return setAcceptedToggle(e.target.checked);
                    }}
                  ></Form.Check>
                </Form.Group>
              </div>
            </div>
            <Button variant="info" onClick={confirm} disabled={!acceptedToggle}>
              Submit
            </Button>
          </Card.Body>
        </Card>
      </footer>
    </Fade>
  );
};
