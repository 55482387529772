import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from "classnames";
import confirm from "components/ConfirmationDialog";
import React, { useState } from "react";
import { Button, FormCheck, OverlayTrigger, Tooltip } from "react-bootstrap";
import { UpdateUserFormModal } from "./UpdateUserFormModal";
import { UserStatus } from "./UserStatus";

export const UserTableRow = ({
  user,
  selectUser,
  index,
  toggleGroupCb,
  toggleTermsCb,
  deleteUserCb,
  resetPasswordCb,
  updateUserCb,
}) => {
  const confirmed = user.status === "CONFIRMED";
  const [showUpdateUserFormModal, setShowUpdateUserFormModal] = useState(false);
  return (
    <tr key={user.username}>
      <UpdateUserFormModal
        show={showUpdateUserFormModal}
        onSubmit={({ username, email, company }) => {
          updateUserCb({ username, email, company });
          setShowUpdateUserFormModal(false);
        }}
        onCancel={(data) => {
          setShowUpdateUserFormModal(false);
        }}
        user={user}
      />
      <td>
        <FormCheck
          aria-label={`Toggle ${user.username}`}
          checked={!!user.selected}
          onChange={() => selectUser(index, !user.selected)}
        />
      </td>
      <td className="email">{user.email}</td>
      <td className="user-status">
        <span
          className={classnames({
            "verified-icon": true,
            verified: confirmed,
            unverified: !confirmed,
          })}
        >
          <OverlayTrigger
            overlay={
              <Tooltip>
                <UserStatus status={user.status} email={user.email} />
              </Tooltip>
            }
          >
            <FontAwesomeIcon icon={confirmed ? "user-check" : "user"} />
          </OverlayTrigger>
        </span>
      </td>
      <td>{user.company}</td>
      <td>
        <FormCheck
          onChange={() => toggleGroupCb(user, "Admin")}
          checked={!!user.admin}
          ref={(el) => el && (el.indeterminate = user.admin === undefined)}
        />
      </td>
      <td>
        <FormCheck
          onChange={() => toggleGroupCb(user, "User")}
          checked={!!user.user}
          ref={(el) => el && (el.indeterminate = user.user === undefined)}
        />
      </td>
      <td>
        <FormCheck
          onChange={() => toggleTermsCb(user, !user.terms)}
          checked={user.terms}
          ref={(el) => el && (el.indeterminate = user.terms === undefined)}
        />
      </td>
      <td>
        <OverlayTrigger
          overlay={
            <Tooltip>
              Delete user <strong>{user.email}</strong>
            </Tooltip>
          }
        >
          <Button
            variant="link"
            onClick={(_e) =>
              confirm(
                `Are you sure you want to delete the user ${user.email}?`
              ).then(
                () => deleteUserCb(user),
                () => {}
              )
            }
          >
            <FontAwesomeIcon icon="trash-alt" />
          </Button>
        </OverlayTrigger>
        <OverlayTrigger
          overlay={
            <Tooltip>
              Reset password for <strong>{user.email}</strong>
            </Tooltip>
          }
        >
          <Button
            variant="link"
            onClick={(_e) =>
              confirm(
                `Are you sure you want to reset the password for the user ${user.email}?`
              ).then(
                () => resetPasswordCb(user),
                () => {}
              )
            }
          >
            <FontAwesomeIcon icon="key" />
          </Button>
        </OverlayTrigger>
        <OverlayTrigger
          overlay={
            <Tooltip>
              Update <strong>{user.email}</strong>
            </Tooltip>
          }
        >
          <Button
            variant="link"
            onClick={() => setShowUpdateUserFormModal(true)}
          >
            <FontAwesomeIcon icon="pen" />
          </Button>
        </OverlayTrigger>
      </td>
    </tr>
  );
};
