import {
  ON_CREATE_TRANSCODING_PROGRESS,
  ON_UPDATE_TRANSCODING_PROGRESS,
} from "graphql/custom/subscriptions";
import find from "lodash/find";
import groupBy from "lodash/groupBy";
import intersection from "lodash/intersection";
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import { useSubscription } from "react-apollo";
import { toast } from "react-toastify";
import Video from "../Video";
import useLocalStorage from "@rehooks/local-storage";

const VideoListBase = ({
  videoItems,
  refetchVideos,
  searchPhrase,
  filterOptions,
  sortOrder,
}) => {
  const [termsAccepted] = useLocalStorage("terms-accepted");

  useSubscription(ON_CREATE_TRANSCODING_PROGRESS, {
    onSubscriptionData: ({
      subscriptionData: {
        data: {
          onCreateTranscodingProgress: { videoId },
        },
      },
    }) => {
      const video = find(videoItems, { id: videoId });
      if (!video) {
        console.warn(
          "onCreateTranscodingProgress subscription without video locally."
        );
        return;
      }

      toast(`Transcoding "${video.title}" (0% done)...`, {
        progress: 0,
        autoclose: false,
        toastId: videoId,
        closeButton: false,
      });
    },
  });

  useSubscription(ON_UPDATE_TRANSCODING_PROGRESS, {
    onSubscriptionData: ({
      subscriptionData: {
        data: {
          onUpdateTranscodingProgress: { videoId, percentComplete, status },
        },
      },
    }) => {
      const video = find(videoItems, { id: videoId });
      if (!video) {
        console.warn(
          "onUpdateTranscodingProgress subscription without video locally."
        );
        return;
      }
      if (status === "COMPLETE") {
        toast.update(videoId, {
          render: `Transcoding "${video.title}" done!`,
          progress: 1,
          closeButton: true,
          autoClose: 10000,
        });
        refetchVideos();
      } else {
        toast.update(videoId, {
          render: `Transcoding "${video.title}" (${percentComplete}% done)...`,
          progress: percentComplete / 100,
        });
      }
    },
  });
  let filteredVideoItems;
  if(!termsAccepted) {
    return filteredVideoItems = [];
  } else {
    filteredVideoItems = videoItems
  }

  if (searchPhrase) {
    filteredVideoItems = filteredVideoItems.filter(
      vid =>
        vid.title.toLowerCase() === searchPhrase ||
        vid.title.toLowerCase().includes(searchPhrase) ||
        vid.description.toLowerCase().includes(searchPhrase) ||
        (vid.customer && vid.customer.toLowerCase().includes(searchPhrase)) ||
        (vid.country && vid.country.toLowerCase().includes(searchPhrase)) ||
        (vid.reference && vid.reference.toLowerCase().includes(searchPhrase)) ||
        (vid.date && vid.date.toLowerCase().includes(searchPhrase))
    );
  }

  if (filterOptions && Array.isArray(filterOptions) && filterOptions.length) {
    const groupedFilterOptions = groupBy(filterOptions, "group");

    filteredVideoItems = Object.entries(groupedFilterOptions).map(([, fo]) =>
      filteredVideoItems.filter(item =>
        fo.some(o =>
          item.categories.items.some(vc => vc.category.id === o.value)
        )
      )
    );
    filteredVideoItems = intersection(...filteredVideoItems);
  }

  filteredVideoItems.sort((a, b) => {
    if (sortOrder.order === "DESC") {
      const tmp = a;
      a = b;
      b = tmp;
    }
    if (sortOrder.field === "date") {
      const aDate = moment(a.date, moment.ISO_8601),
        bDate = moment(b.date, moment.ISO_8601);
      if (!aDate.isValid()) {
        return -1;
      }
      if (!bDate.isValid()) {
        return 1;
      }
      return aDate - bDate;
    } else if (sortOrder.field === "createdAt") {
      return moment(a.createdAt) - moment(b.createdAt);
    } else if (sortOrder.field === "title") {
      return (a.title || "").localeCompare(b.title || "");
    } else {
      return a[sortOrder.field] - b[sortOrder.field];
    }
  });

  return (
    <div className="video-category-container">
      <ul className="video-list">
        {filteredVideoItems.map(video => (
          <Video key={video.id} info={video} />
        ))}
      </ul>
    </div>
  );
};

VideoListBase.propTypes = {
  searchPhrase: PropTypes.string,
  filterOptions: PropTypes.array,
  sortOrder: PropTypes.object,
  refetchVideos: PropTypes.func
};

export default VideoListBase;
