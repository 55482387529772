import { GQL_LIMIT } from "constants.js";
import { LIST_VIDEOS } from "graphql/custom/queries";
import PropTypes from "prop-types";
import React from "react";
import { useQuery } from "react-apollo";
import { useSessionStorage } from "react-use";
import { useGlobal } from "reactn";
import VideoListBase from "./base";
import { Header } from "./Header";

const VideoList = params => {
  const [publicOnly, setPublicOnly] = useSessionStorage("publicOnly", false);

  const [isAdmin] = useGlobal("isAdmin");
  const [auth] = useGlobal("auth");

  const listVideoParams = {
    variables: {
      limit: GQL_LIMIT,
      categoriesLimit: GQL_LIMIT,
      playlistVideosLimit: GQL_LIMIT,
    },
  };
  if (!isAdmin) {
    listVideoParams.variables.filter = {
      or: [
        { approved: { eq: true } },
        auth ? { owner: { eq: auth.sub } } : false,
      ],
    };
  }
  const videos = useQuery(LIST_VIDEOS, listVideoParams);

  if (videos.loading) {
    return "Loading...";
  }
  if (videos.error) {
    console.warn(videos.error);
  }

  if (!videos.data) {
    return;
  }

  let items = videos.data.listVideos.items;

  if (publicOnly) {
    items = items.filter(item => item.public);
  }

  return <>
    <div className="archive-content-container">
      <div className="archive-home active-container">
        <VideoListBase
          {...params}
          videoItems={items}
          refetchVideos={videos.refetch}
        />
      </div>
    </div>
    <Header publicOnly={publicOnly} setPublicOnly={setPublicOnly} />
  </>;
};

VideoList.propTypes = {
  searchPhrase: PropTypes.string,
  filterOptions: PropTypes.array,
  sortOrder: PropTypes.object,
};

export default VideoList;
