import { GQL_LIMIT } from "constants.js";
import { LIST_CATEGORY_GROUPS } from "graphql/custom/queries";
import PropTypes from "prop-types";
import React from "react";
import { useQuery } from "react-apollo";
import Select, { components } from "react-select";

const Filter = ({ filterOptions, setFilterOptions }) => {
  const categoryGroups = useQuery(LIST_CATEGORY_GROUPS, {
    options: { variables: { groupLimit: GQL_LIMIT, categoryLimit: GQL_LIMIT } },
  });

  if (categoryGroups.loading) {
    return "Loading...";
  }
  if (categoryGroups.error) {
    console.warn(categoryGroups.error.message);
  }
  const allFilterOptions = categoryGroups.data.listCategoryGroups.items
    .map(grp => ({
      label: grp.label,
      value: grp.id,
      order: grp.order,
      options: grp.categories.items
        .map(cat => ({
          label: cat.label,
          value: cat.id,
          group: grp.id,
          order: cat.order,
        }))
        .sort((a, b) => a.order - b.order),
    }))
    .sort((a, b) => a.order - b.order);

  const groupStyles = {
    display: "flex",
    alignItems: "center",
    background: "#7fa2b7",
    padding: ".5rem",
    color: "#fff",
  };

  const GroupHeading = props => (
    <div style={groupStyles}>
      <components.GroupHeading {...props} />
    </div>
  );

  const Option = props => {
    return <components.Option {...props} />;
  };

  return (
    <div className="search-filter">
      <Select
        closeMenuOnSelect={false}
        value={filterOptions}
        onChange={e => setFilterOptions(e)}
        options={allFilterOptions}
        components={{ Option, GroupHeading }}
        isMulti={true}
        isSearchable={false}
        placeholder="Select categories"
        styles={{
          option: base => ({
            ...base,
            fontSize: ".8rem",
          }),
          groupHeading: base => ({
            ...base,
            flex: "1 1",
            color: "white",
            margin: 0,
          }),
        }}
      />
    </div>
  );
};
Filter.propTypes = {
  filterOptions: PropTypes.array,
  setFilterOptions: PropTypes.func.isRequired,
};
export default Filter;
