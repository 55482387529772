import Amplify, { Storage } from "aws-amplify";
import classNames from "classnames";
import confirm from "components/ConfirmationDialog";
import { GQL_LIMIT } from "constants.js";
import { Formik } from "formik";
import {
  CREATE_VIDEO,
  CREATE_VIDEO_CATEGORY,
  CREATE_VIDEO_SLUG,
  DELETE_VIDEO,
  DELETE_VIDEO_CATEGORY,
  DELETE_VIDEO_SLUG,
  UPDATE_VIDEO,
} from "graphql/custom/mutations";
import {
  GET_VIDEO_BY_SLUG,
  LIST_CATEGORY_GROUPS,
} from "graphql/custom/queries";
import {
  listUsers,
  makeFilePrivate,
  makeFilePublic,
} from "helpers/adminQueries";
import has from "lodash/has";
import uniqBy from "lodash/uniqBy";
import React, { useEffect, useState } from "react";
import { useLazyQuery, useMutation, useQuery } from "react-apollo";
import { Button, Form } from "react-bootstrap";
import Alert from "react-bootstrap/Alert";
import ProgressBar from "react-bootstrap/ProgressBar";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Dropzone from "react-dropzone";
import { useHistory, useParams } from "react-router-dom";
import Select, { components } from "react-select";
import { useGlobal } from "reactn";
import * as yup from "yup";
import moment from "moment";
import { useToggle } from "react-use";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Slug from 'slug'
import { nanoid } from "nanoid";
const aws_config = Amplify.configure();
const schema = yup.object({
  title: yup.string().max(60).required(),
  description: yup.string().required(),
  categories: yup.array(yup.object({ key: yup.string(), value: yup.string() })),
  customer: yup.string(),
  country: yup.string(),
  date: yup.date(),
});
const FILENAME_RE = /(?<fn>.*?)\.(?<ext>\w+)$/;

async function storeToS3(id, file, progress) {
  const matches = file.name.match(FILENAME_RE);
  if (!matches) {
    throw Error("Missing file extension");
  }
  const result = await Storage.put(`${id}.${matches.groups.ext}`, file, {
    customPrefix: { public: "uploads/" },
    contentType: file.type,
    progressCallback: progress,
  });
  return result;
}

const groupStyles = {
  display: "flex",
  alignItems: "center",
  background: "#7fa2b7",
  padding: ".5rem",
  color: "#fff",
};

const GroupHeading = (props) => (
  <div style={groupStyles}>
    <components.GroupHeading {...props} />
  </div>
);

const DatePickerField = ({ name, value, onChange }) => {
  return (
    <DatePicker
      id="videoDate"
      placeholderText="Date"
      className="form-control"
      selected={(value && new Date(value)) || null}
      onChange={(val) => {
        onChange(name, val);
      }}
      isClearable
      todayButton="Today"
      dateFormat="MMMM yyyy"
      showMonthYearPicker
    />
  );
};
const AddVideo = (props) => {
  const { mode } = props;
  const [isAdmin] = useGlobal("isAdmin");

  const history = useHistory();
  const { slug } = useParams();

  const [selectedCategories, setSelectedCategories] = useState([]);
  const [hasSelectedCategories, toggleHasSelectedCategories] = useToggle(false);
  const [categoryActions, setCategoryActions] = useState([]);
  const [uploadProgress, setUploadProgress] = useState(null);
  const [uploadFile, setUploadFile] = useState(null);
  const [uploadError, setUploadError] = useState(null);
  const [users, setUsers] = useState([]);

  const [loadVideo, selectedVideo] = useLazyQuery(GET_VIDEO_BY_SLUG, {
    variables: {
      slug,
      categoriesLimit: GQL_LIMIT,
      playlistVidesLimit: GQL_LIMIT,
    },
  });

  let filterOptions;
  const categoryGroups = useQuery(LIST_CATEGORY_GROUPS, {
    variables: { groupLimit: GQL_LIMIT, categoryLimit: GQL_LIMIT },
  });

  const [updateVideoMutation] = useMutation(UPDATE_VIDEO);
  const [createVideoMutation] = useMutation(CREATE_VIDEO, {
    refetchQueries: ["ListVideos"],
  });
  const [deleteVideoMutation] = useMutation(DELETE_VIDEO, {
    refetchQueries: ["ListVideos"],
  });
  const [deleteVideoCategoryMutation] = useMutation(DELETE_VIDEO_CATEGORY, {
    refetchQueries: ["ListVideos"],
  });
  const [createVideoCategoryMutation] = useMutation(CREATE_VIDEO_CATEGORY, {
    refetchQueries: ["ListVideos"],
  });

  const [createVideoSlugMutation] = useMutation(CREATE_VIDEO_SLUG);
  const [deleteVideoSlugMutation] = useMutation(DELETE_VIDEO_SLUG);
  useEffect(() => {
    (async () => {
      if (isAdmin) {
        const { Users } = await listUsers();
        const mappedUsers = Users.map((user) => {
          return user.Attributes.reduce(
            // eslint-disable-next-line no-sequences
            (obj, item) => ((obj[item.Name] = item.Value), obj),
            {}
          );
        });
        setUsers(mappedUsers);
      }
    })();
  }, [isAdmin]);

  useEffect(() => {
    if (!selectedVideo.called || selectedVideo.loading) {
      return;
    }

    const video = selectedVideo.data.getVideoSlug.video;
    if (has(video, "categories.items")) {
      let selectedCategories = video.categories.items
        .filter((vc) => vc != null)
        .map((vc) => {
          return {
            label: vc.category.label,
            value: vc.category.id,
          };
        });
      selectedCategories = uniqBy(selectedCategories, "value");

      setSelectedCategories(selectedCategories);
    }
    toggleHasSelectedCategories(true);
  }, [mode, selectedVideo, toggleHasSelectedCategories]);

  if (!selectedVideo.called && slug) {
    loadVideo();
  }
  const editVideo = mode === "edit";

  if (
    categoryGroups.loading ||
    (selectedVideo.called && selectedVideo.loading)
  ) {
    return "Loading...";
  }
  if (categoryGroups.error) {
    console.warn(categoryGroups.error.message);
  }
  if (selectedVideo.error) {
    console.warn(selectedVideo.error.message);
  }

  let video = {};
  if (selectedVideo.called  && selectedVideo.data.getVideoSlug) {
    video = selectedVideo.data.getVideoSlug.video;
    if (!hasSelectedCategories) {
      return "Loading...";
    }
  }

  const executeActions = async ({ video }) => {
    let categories = [];
    const { id: videoId, categories: _categories } = video;
    if (_categories && _categories.items) {
      categories = _categories.items;
    }
    for (const action of categoryActions) {
      switch (action.action) {
        case "remove-value": {
          if (categories.length) {
            const to_remove = categories.filter(
              (cat) => cat.category.id === action.removedValue.value
            );
            for (const cat of to_remove) {
              await deleteVideoCategoryMutation({
                variables: { input: { id: cat.id } },
              });
            }
          }
          break;
        }
        case "select-option": {
          await createVideoCategoryMutation({
            variables: {
              input: {
                videoCategoryVideoId: videoId,
                videoCategoryCategoryId: action.option.value,
              },
            },
          });
          break;
        }
        case "clear": {
          if (categories.length) {
            for (const cat of categories) {
              await deleteVideoCategoryMutation({
                variables: { input: { id: cat.id } },
              });
            }
          }

          break;
        }
        default: {
          console.warn("Unhandled action: %s", action);
          break;
        }
      }
    }
    setCategoryActions([]);
  };

  const createSlug = async (video) => {
    const slugBase = Slug(
      `${moment(video.createdAt).format("YYYY-MM-DD")} ${video.title}`,
      { lower: false, trim: true }
    );

    let videoSlugId = null;
    do {
      const slug = `${slugBase}-${nanoid(6)}`;
      const result = await createVideoSlugMutation({
        variables: {
          input: { videoID: video.id, slugBase, slug },
        },
      });

      if (!result.errors) {
        videoSlugId = result.data.createVideoSlug.slug;
      }
    } while (videoSlugId === null);
    await updateVideoMutation({
      variables: { input: { id: video.id, videoSlugId } },
    });

    return videoSlugId;
  };

  const deleteSlug = async (slug) => {
    await deleteVideoSlugMutation({ variables: { input: { slug } } });
  };

  const deleteVideo = async () => {
    for (const vc of video.categories.items) {
      await deleteVideoCategoryMutation({
        variables: { input: { id: vc.id } },
      });
    }

    if (video.videoSlugId) {
      await deleteSlug(video.videoSlugId);
    }
    await deleteVideoMutation({ variables: { input: { id: video.id } } });
    history.replace("/v");
  };

  const createVideo = async (data) => {
    if (!uploadFile) {
      throw new Error("Please select a video to upload!");
    }

    const input = {
      title: data.title,
      description: data.description,
      date: data.date || null,
      customer: data.customer || null,
      country: data.country || null,
    };

    if (isAdmin) {
      input.approved = data.flags.includes("isApproved");
      if (input.approved) {
        input.approvedAt = moment().format();
      }
      input.public = data.flags.includes("isPublic");
      input.owner = data.owner || null;
    }
    const createVideoMutationResult = await createVideoMutation({
      variables: { input },
    });

    const newVideo = createVideoMutationResult.data.createVideo;
    await createSlug(newVideo);

    try {
      await storeToS3(newVideo.id, uploadFile[0], setUploadProgress);
    } catch (e) {
      throw e;
    }

    await executeActions({ video: newVideo });

    history.replace("/v");
  };

  const updateVideo = async (data) => {
    const id = video.id;
    if (uploadFile) {
      try {
        await storeToS3(id, uploadFile[0], setUploadProgress);
      } catch (e) {
        throw e;
      }
    }

    const input = {
      id,
      title: data.title,
      description: data.description,
      date: data.date || null,
      customer: data.customer || null,
      country: data.country || null,
      ready: !uploadFile,
    };

    if (isAdmin) {
      input.approved = data.flags.includes("isApproved");
      input.approvedAt = video.approvedAt;
      if (input.approved && input.approvedAt == null) {
        input.approvedAt = moment().format();
      }
      if (!input.approved) {
        input.approvedAt = null;
      }

      input.public = data.flags.includes("isPublic");
      input.owner = data.owner || null;
    }
    const updateVideoMutationResult = await updateVideoMutation({
      variables: { input },
    });
    const oldSlugId = video.videoSlugId;

    const newVideoSlugId = await createSlug(updateVideoMutationResult.data.updateVideo);

    const res = await selectedVideo.refetch({slug: newVideoSlugId});
    if (isAdmin && res.data.getVideoSlug.video.mp4) {
      if (res.data.getVideoSlug.video.public) {
        await makeFilePublic(
          aws_config.aws_user_files_s3_bucket,
          `public/${res.data.getVideoSlug.video.mp4}`
        );
        await makeFilePublic(
          aws_config.aws_user_files_s3_bucket,
          `public/${res.data.getVideoSlug.video.thumb}`
        );
      } else {
        await makeFilePrivate(
          aws_config.aws_user_files_s3_bucket,
          `public/${res.data.getVideoSlug.video.mp4}`
        );
        await makeFilePrivate(
          aws_config.aws_user_files_s3_bucket,
          `public/${res.data.getVideoSlug.video.thumb}`
        );
      }
    }
    await executeActions({ video });
    if (video.videoSlugId) {
      await deleteSlug(oldSlugId);
    }

    history.replace("/v");
  };

  const addAction = (action) => {
    setCategoryActions([...categoryActions, action]);
  };

  filterOptions = categoryGroups.data.listCategoryGroups.items
    .map((grp) => ({
      label: grp.label,
      value: grp.id,
      order: grp.order,
      options: grp.categories.items
        .map((cat) => ({
          label: cat.label,
          value: cat.id,
          order: cat.order,
        }))
        .sort((a, b) => a.order - b.order),
    }))
    .sort((a, b) => a.order - b.order);

  const flags = [];
  if (
    (typeof video.approved === "boolean" && video.approved) ||
    (!editVideo && isAdmin)
  ) {
    flags.push("isApproved");
  }
  if (typeof video.public === "boolean" && video.public) {
    flags.push("isPublic");
  }
  return (
    <Formik
      validationSchema={schema}
      validateOnBlur={false}
      onSubmit={editVideo ? updateVideo : createVideo}
      onReset={() => history.replace("/v")}
      initialValues={{
        title: video.title || "",
        description: video.description || "",
        categories: editVideo ? selectedCategories : [],
        customer: video.customer || "",
        country: video.country || "",
        date: video.date || (editVideo ? "" : moment().format()),
        flags,
        toto: false,
        owner: video.owner || "",
      }}
    >
      {({
        setFieldTouched,
        setFieldValue,
        isSubmitting,
        handleSubmit,
        setSubmitting,
        handleChange,
        handleReset,
        handleBlur,
        values,
        touched,
        errors,
      }) => (
        <Form
          id="video-form"
          className="video-edit-upload-form video-form-active"
          noValidate
          onSubmit={handleSubmit}
        >
          <div>
            {editVideo ? (
              <h1 className="mb-3">Edit video</h1>
            ) : (
              <h1 className="mb-3">Add video to library</h1>
            )}
            <p>
              Fill out the form below and upload your new video to the library
            </p>
          </div>

          {isAdmin && (
            <>
              <Form.Group
                controlId="videoPublic"
                className="form-label-group form-checkbox-group"
              >
                <Form.Check
                  type="switch"
                  label="Video is public"
                  name="flags"
                  value="isPublic"
                  checked={values.flags.includes("isPublic")}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Form.Group>

              <Form.Group
                controlId="videoApproved"
                className="form-label-group form-checkbox-group"
              >
                <Form.Check
                  type="switch"
                  label="Video is approved"
                  name="flags"
                  value="isApproved"
                  checked={values.flags.includes("isApproved")}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Form.Group>
            </>
          )}
          <Form.Group controlId="videoTitle" className="form-label-group">
            <Form.Control
              placeholder="Video title"
              autoFocus
              name="title"
              value={values.title}
              onChange={handleChange}
              onBlur={handleBlur}
              isInvalid={!!errors.title && touched.title}
            />
            <Form.Label sm={2}>Video title</Form.Label>
            <Form.Control.Feedback type="invalid">
              {errors.title}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="videoDescription" className="form-label-group">
            <Form.Control
              as="textarea"
              placeholder="Description"
              name="description"
              value={values.description}
              onChange={handleChange}
              onBlur={handleBlur}
              isInvalid={!!errors.description && touched.description}
            />
            <Form.Label sm={2}>Description</Form.Label>
            <Form.Control.Feedback type="invalid">
              {errors.description}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group
            controlId="selectedCategories"
            className="form-label-group category-selector"
          >
            <Select
              name="categories"
              onBlur={() => setFieldTouched("categories", true)}
              onChange={(categories, action) => {
                setFieldValue("categories", categories);
                addAction(action);
              }}
              closeMenuOnSelect={false}
              value={(() => values.categories)()}
              options={filterOptions}
              components={{ /* Options, */ GroupHeading }}
              isMulti={true}
              isSearchable={true}
              placeholder="Select categories"
              styles={{
                option: (base) => ({
                  ...base,
                  fontSize: ".8rem",
                }),
                groupHeading: (base) => ({
                  ...base,
                  flex: "1 1",
                  color: "white",
                  margin: 0,
                }),
              }}
            />
          </Form.Group>
          <Form.Group controlId="videoCustomer" className="form-label-group">
            <Form.Control
              placeholder="Customer"
              name="customer"
              value={values.customer}
              onChange={handleChange}
              onBlur={handleBlur}
              isInvalid={!!errors.customer && touched.customer}
            />
            <Form.Label sm={2}>Customer</Form.Label>
            <Form.Control.Feedback type="invalid">
              {errors.customer}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="videoCountry" className="form-label-group">
            <Form.Control
              placeholder="Country"
              name="country"
              value={values.country}
              onChange={handleChange}
              onBlur={handleBlur}
              isInvalid={!!errors.country && touched.country}
            />
            <Form.Label sm={2}>Country</Form.Label>
            <Form.Control.Feedback type="invalid">
              {errors.country}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="videoDate" className="form-label-group">
            <DatePickerField
              name="date"
              value={values.date}
              onChange={setFieldValue}
            />
          </Form.Group>
          {isAdmin && (
            <Form.Group controlId="videoOwner" className="form-label-group">
              <Form.Control
                as="select"
                placeholder="Owner"
                name="owner"
                value={values.owner}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={!!errors.owner && touched.owner}
              >
                <option value="">None</option>
                {users.map((user) => (
                  <option value={user.sub} key={user.sub}>
                    {user.email}
                  </option>
                ))}
              </Form.Control>
              <Form.Label sm={2}>Owner</Form.Label>

              <Form.Control.Feedback type="invalid">
                {errors.owner}
              </Form.Control.Feedback>
            </Form.Group>
          )}
          <FileDropper {...{ setUploadFile, uploadProgress }} />
          <Button
            variant="secondary"
            className="video-btn-cancel"
            size="lg"
            disabled={!!uploadProgress || isSubmitting}
            onClick={handleReset}
          >
            Cancel
          </Button>
          <Button
            variant="outline-danger"
            size="lg"
            className="video-btn-delete"
            disabled={!!uploadProgress || isSubmitting}
            hidden={!editVideo}
            onClick={(e) => {
              confirm(
                <>
                  Are you certain you wish to proceed deleting the video{" "}
                  <strong>{video.title}</strong>
                </>
              )
                .then(deleteVideo)
                .catch(() => {});
            }}
          >
            Permanently delete this video
          </Button>
          <Button
            type="submit"
            variant="primary"
            className="video-btn-save"
            size="lg"
            disabled={!!uploadProgress || isSubmitting}
          >
            Save
          </Button>
          <Alert
            variant="danger"
            show={!!uploadError}
            onClose={() => setUploadError()}
            dismissible
          >
            <Alert.Heading>Error!</Alert.Heading>
            <p>{uploadError}</p>
          </Alert>
        </Form>
      )}
    </Formik>
  );
};

function FileDropper({ hidden, setUploadFile, uploadProgress }) {
  return hidden ? null : (
    <Dropzone multiple={false} onDrop={setUploadFile} accept="video/*">
      {({
        getRootProps,
        getInputProps,
        acceptedFiles,
        isDragActive,
        isDragAccept,
        isDragReject,
      }) => (
        <div className="text-center mt-5">
          <section>
            <div
              {...getRootProps()}
              className={classNames("video-upload-control", {
                "video-upload-control-active": isDragActive,
                "video-upload-control-rejected": isDragReject,
                "video-upload-control-accepted": isDragAccept,
              })}
            >
              <input {...getInputProps()} />
              <p>Drag 'n' drop your video here, or click to select the video</p>
              <Alert variant="info" className="video-info-alert d-flex">
                <FontAwesomeIcon icon="info" size="1x"></FontAwesomeIcon>
                <p className="mb-0">
                  Your video will be sent for review and approval by the
                  administrator before it is published.
                </p>
              </Alert>
            </div>
            {uploadProgress && (
              <ProgressBar
                animated={uploadProgress.loaded / uploadProgress.total !== 1}
                striped
                now={uploadProgress.loaded}
                max={uploadProgress.total}
                label={`${Number.parseFloat(
                  (uploadProgress.loaded / uploadProgress.total) * 100
                ).toFixed(2)}%`}
                variant={
                  uploadProgress.loaded === uploadProgress.total
                    ? "success"
                    : "info"
                }
              />
            )}
          </section>
          <ul className="list-group mt-2">
            {acceptedFiles.length > 0 &&
              acceptedFiles.map((acceptedFile) => (
                <li
                  key={acceptedFile.name}
                  className="list-group-item list-group-item-success"
                >
                  {acceptedFile.name} ({acceptedFile.size})
                </li>
              ))}
          </ul>
        </div>
      )}
    </Dropzone>
  );
}

export default AddVideo;
