import gql from "graphql-tag";

export const ON_CREATE_TRANSCODING_PROGRESS = gql`
  subscription OnCreateTranscodingProgress {
    onCreateTranscodingProgress {
      videoId
      jobId
      status
      percentComplete
    }
  }
`;
export const ON_UPDATE_TRANSCODING_PROGRESS = gql`
  subscription OnUpdateTranscodingProgress {
    onUpdateTranscodingProgress {
      videoId
      jobId
      status
      percentComplete
    }
  }
`;
