import React from "react";
import PropTypes from "prop-types";
import "./IconButton.scss";
import { OverlayTrigger, Tooltip, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const IconButton = ({ tooltip, icon, ...params }) => (
  <OverlayTrigger className="IconButton" overlay={<Tooltip>{tooltip}</Tooltip>}>
    <Button {...params}>
      <FontAwesomeIcon {...{ icon }} />
    </Button>
  </OverlayTrigger>
);

IconButton.propTypes = {
  tooltip: PropTypes.node,
  onClick: PropTypes.func,
  variant: PropTypes.string,
  icon: PropTypes.string,
};

IconButton.defaultProps = { variant: "dark", onClick() {} };

export default IconButton;
