import { API } from "aws-amplify";

const adminGet = async (path, params) => {
  let apiName = "AdminQueries";
  return await API.get(apiName, path, {
    queryStringParameters: params,
  });
};

const adminPost = async (path, params) => {
  let apiName = "AdminQueries";
  return await API.post(apiName, path, {
    body: params,
  });
};

export const makeFilePublic = async (bucket, key) => {
  let path = "/makeFilePublic";
  const params = {
    bucket,
    key,
  };
  return adminPost(path, params);
};

export const makeFilePrivate = async (bucket, key) => {
  let path = "/makeFilePrivate";
  const params = {
    bucket,
    key,
  };
  return adminPost(path, params);
};

export const addUser = async (username, attributes) => {
  let path = "/addUser";
  const params = {
    username,
    attributes,
  };
  return adminPost(path, params);
};

export const updateUserAttributes = async (username, attributes) => {
  let path = "/updateUserAttributes";
  const params = {
    username,
    attributes,
  };
  return adminPost(path, params);
};

export const deleteUser = async username => {
  let path = "/deleteUser";
  const params = {
    username,
  };
  return adminPost(path, params);
};

export const addUserToGroup = async (username, groupname) => {
  let path = "/addUserToGroup";
  const params = {
    username,
    groupname,
  };
  return adminPost(path, params);
};
export const removeUserFromGroup = async (username, groupname) => {
  let path = "/removeUserFromGroup";
  const params = {
    username,
    groupname,
  };
  return adminPost(path, params);
};

export const resetUserPassword = async username => {
  let path = "/resetUserPassword";
  const params = {
    username,
  };
  return adminPost(path, params);
};

export const listUsersInGroup = async (
  groupname,
  limit = undefined,
  nextToken = undefined
) => {
  let path = "/listUsersInGroup";
  const params = {
    groupname,
    limit,
    token: nextToken,
  };
  return adminGet(path, params);
};
export const recursiveListUsersInGroup = async groupname => {
  let retval = { Users: [] };
  const _listUsersInGroup = nextToken =>
    listUsersInGroup(groupname, undefined, nextToken).then(params => {
      retval.Users = retval.Users.concat(params.Users);
      if (params.NextToken) {
        return _listUsersInGroup(params.NextToken);
      } else {
        return retval;
      }
    });
  return _listUsersInGroup(undefined);
};

export const listUsers = async (limit = undefined, nextToken = undefined) => {
  let path = "/listUsers";
  const params = {
    limit: limit,
    token: nextToken,
  };
  return adminGet(path, params);
};

export const getUser = async(sid) => {
  const path = "/GetUser";
  const params = {
    username: sid
  }
  let user= await adminGet(path, params);
  user.UserAttributes = user.UserAttributes.reduce(
    // eslint-disable-next-line no-sequences
    (obj, item) => ((obj[item.Name] = item.Value), obj),
    {}
  )
  return user;
}
export const recursiveListUsers = async groupname => {
  let retval = { Users: [] };
  const _listUsers = nextToken =>
    listUsers(undefined, nextToken).then(params => {
      retval.Users = retval.Users.concat(params.Users);
      if (params.NextToken) {
        return _listUsers(params.NextToken);
      } else {
        return retval;
      }
    });
  return _listUsers(undefined);
};

export const listUserGroups = async () => {
  const { Users } = await recursiveListUsers();

  const usernames = (await recursiveListUsersInGroup("User")).Users.map(
    user => user.Username
  );
  const adminnames = (await recursiveListUsersInGroup("Admin")).Users.map(
    admin => admin.Username
  );
  Users.forEach(user => {
    user.Attributes = user.Attributes.reduce(
      // eslint-disable-next-line no-sequences
      (obj, item) => ((obj[item.Name] = item.Value), obj),
      {}
    );
    user.groups = {
      User: usernames.includes(user.Username),
      Admin: adminnames.includes(user.Username),
    };
  });
  return Users;
};
