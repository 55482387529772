import { GQL_LIMIT } from "constants.js";
import {
  GET_PLAYLIST,
  LIST_PLAYLIST_VIDEOS_BY_PLAYLIST,
} from "graphql/custom/queries";
import React from "react";
import { useQuery } from "react-apollo";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useKey } from "react-use";
import { PlaylistCard } from "./PlaylistCard";
export function Playlist() {
  const { playlistId } = useParams();
  const location = useLocation();
  const history = useHistory();
  const playlist = useQuery(GET_PLAYLIST, {
    variables: { id: playlistId },
  });
  const playlistVideos = useQuery(LIST_PLAYLIST_VIDEOS_BY_PLAYLIST, {
    variables: { playlistID: playlistId, limit: GQL_LIMIT, playlistVideosLimit: GQL_LIMIT },
  });
  const closePlayer = () => {
    if (location.state && location.state.fromDashboard) {
      history.goBack();
    } else {
      history.replace("/v");
    }
  };

  // Close on ESC
  useKey(ev => ev.keyCode === 27, closePlayer);

  if (playlist.loading || playlistVideos.loading) {
    return "Loading...";
  }

  if (playlist.error) {
    console.warn(playlist.error.message);
  }
  if (playlistVideos.error) {
    console.warn(playlistVideos.error.message);
  }

  return (
    <PlaylistCard
      playlist={playlist.data.getPlaylist}
      playlistVideos={playlistVideos.data.playlistVideosByPlaylist.items}
      onClose={closePlayer}
    />
  );
}
