import { ForgotPassword } from "aws-amplify-react";
import React from "react";
import { Button, Form } from "react-bootstrap";

export class CustomForgotPassword extends ForgotPassword {
  constructor(props) {
    super(props);

    this._validAuthStates = ["forgotPassword"];
  }

  submitView() {
    return (
      <div>
        <Form.Group controlId="code">
          <Form.Label>Code</Form.Label>
          <Form.Control
            placeholder="Code"
            autoComplete="off"
            key="code"
            name="code"
            onChange={this.handleInputChange}
          />
        </Form.Group>
        <Form.Group controlId="password">
          <Form.Label>New Password</Form.Label>
          <Form.Control
            placeholder="New Password"
            autoComplete="off"
            type="password"
            key="password"
            name="password"
            onChange={this.handleInputChange}
          />
        </Form.Group>
      </div>
    );
  }
  sendView() {
    return (
      <div>
        <Form.Group controlId="code">
          <Form.Label>Email *</Form.Label>
          <Form.Control
            autoFocus
            placeholder="Enter your email"
            key="email"
            name="email"
            type="email"
            onChange={this.handleInputChange}
          />
        </Form.Group>
      </div>
    );
  }

  showComponent(theme) {
    const { hide, authData = {} } = this.props;
    if (hide && hide.includes(CustomForgotPassword)) {
      return null;
    }
    return (
      <div className="login">
        <div className="blurred-bg"></div>
        <div className="login-form-container">
          <form className="form-signin" onSubmit={this.signIn}>
            <header className="text-center mb-4">
              <div className="mb-4 login-logo"></div>
              <h1 className="h1 mb-3">Reset your password</h1>
            </header>
            <div>
              {this.state.delivery || authData.username
                ? this.submitView()
                : this.sendView()}
            </div>
            <footer>
              <Form.Row>
                {this.state.delivery || authData.username ? (
                  <Button block size="lg" onClick={this.submit}>
                    Submit
                  </Button>
                ) : (
                  <Button block size="lg" onClick={this.send}>
                    Send Code
                  </Button>
                )}
              </Form.Row>

              <Form.Row>
                {this.state.delivery || authData.username ? (
                  <Button variant="link" onClick={this.send}>
                    Resend Code
                  </Button>
                ) : (
                  <Button
                    variant="link"
                    onClick={() => this.changeState("signIn")}
                  >
                    Back to Sign In
                  </Button>
                )}
              </Form.Row>
            </footer>
          </form>
        </div>
      </div>
    );
  }
}
