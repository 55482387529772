import gql from "graphql-tag";
import {
  videoFieldsFragment,
  categoryFieldsFragment,
  categoryGroupFieldsFragment,
  playlistFieldsFragment,
  playlistVideoFieldsFragment,
  videoSlugFieldsFragment,
} from "./fragments";

export const CREATE_CATEGORY_GROUP = gql`
  mutation ($input: CreateCategoryGroupInput!) {
    createCategoryGroup(input: $input) {
      ...categoryGroupFields
    }
  }
  ${categoryGroupFieldsFragment}
`;
export const UPDATE_CATEGORY_GROUP = gql`
  mutation ($input: UpdateCategoryGroupInput!) {
    updateCategoryGroup(input: $input) {
      ...categoryGroupFields
    }
  }
  ${categoryGroupFieldsFragment}
`;
export const DELETE_CATEGORY_GROUP = gql`
  mutation ($input: DeleteCategoryGroupInput!) {
    deleteCategoryGroup(input: $input) {
      ...categoryGroupFields
    }
  }
  ${categoryGroupFieldsFragment}
`;
export const CREATE_CATEGORY = gql`
  mutation ($input: CreateCategoryInput!) {
    createCategory(input: $input) {
      ...categoryFields
      group {
        ...categoryGroupFields
      }
    }
  }
  ${categoryFieldsFragment}
  ${categoryGroupFieldsFragment}
`;
export const UPDATE_CATEGORY = gql`
  mutation ($input: UpdateCategoryInput!) {
    updateCategory(input: $input) {
      ...categoryFields
      group {
        ...categoryGroupFields
      }
    }
  }
  ${categoryFieldsFragment}
  ${categoryGroupFieldsFragment}
`;
export const DELETE_CATEGORY = gql`
  mutation ($input: DeleteCategoryInput!) {
    deleteCategory(input: $input) {
      ...categoryFields
      group {
        ...categoryGroupFields
      }
    }
  }
  ${categoryFieldsFragment}
  ${categoryGroupFieldsFragment}
`;

export const UPDATE_VIDEO = gql`
  mutation UpdateVideo(
    $input: UpdateVideoInput!
    $condition: ModelVideoConditionInput
  ) {
    updateVideo(input: $input, condition: $condition) {
      ...videoFields
    }
  }
  ${videoFieldsFragment}
`;

export const UPDATE_VIDEO_PLAY_COUNT = gql`
  mutation UpdateVideoPlayCount($videoId: ID!, $playCount: Int) {
    updateVideo(input: { id: $videoId, playCount: $playCount }) {
      playCount
    }
  }
`;
export const CREATE_VIDEO = gql`
  mutation CreateVideo(
    $input: CreateVideoInput!
    $condition: ModelVideoConditionInput
  ) {
    createVideo(input: $input, condition: $condition) {
      ...videoFields
    }
  }
  ${videoFieldsFragment}
`;

export const DELETE_VIDEO = gql`
  mutation DeleteVideo(
    $input: DeleteVideoInput!
    $condition: ModelVideoConditionInput
  ) {
    deleteVideo(input: $input, condition: $condition) {
      ...videoFields
    }
  }
  ${videoFieldsFragment}
`;

export const CREATE_VIDEO_SLUG = gql`
  mutation CreateVideoSlug(
    $input: CreateVideoSlugInput!
    $condition: ModelVideoSlugConditionInput
  ) {
    createVideoSlug(input: $input, condition: $condition) {
      ...videoSlugFields
    }
  }
  ${videoSlugFieldsFragment}
`;

export const UPDATE_VIDEO_SLUG = gql`
  mutation UpdateVideoSlug(
    $input: UpdateVideoSlugInput!
    $condition: ModelVideoSlugConditionInput
  ) {
    updateVideoSlug(input: $input, condition: $condition) {
      ...videoSlugFields
    }
  }
  ${videoSlugFieldsFragment}
`;

export const DELETE_VIDEO_SLUG = gql`
  mutation DeleteVideoSlug(
    $input: DeleteVideoSlugInput!
    $condition: ModelVideoSlugConditionInput
  ) {
    deleteVideoSlug(input: $input, condition: $condition) {
      ...videoSlugFields
    }
  }
  ${videoSlugFieldsFragment}
`;

export const CREATE_PLAYLIST = gql`
  mutation CreatePlaylist($input: CreatePlaylistInput!) {
    createPlaylist(input: $input) {
      ...playlistFields
    }
  }
  ${playlistFieldsFragment}
`;
export const UPDATE_PLAYLIST = gql`
  mutation UpdatePlaylist($input: UpdatePlaylistInput!) {
    updatePlaylist(input: $input) {
      ...playlistFields
    }
  }
  ${playlistFieldsFragment}
`;
export const DELETE_PLAYLIST = gql`
  mutation DeletePlaylist($input: DeletePlaylistInput!) {
    deletePlaylist(input: $input) {
      ...playlistFields
    }
  }
  ${playlistFieldsFragment}
`;

export const CREATE_PLAYLIST_VIDEO = gql`
  mutation CreatePlaylistVideo($input: CreatePlaylistVideoInput!) {
    createPlaylistVideo(input: $input) {
      ...playlistVideoFields
    }
  }
  ${playlistVideoFieldsFragment}
`;

export const DELETE_PLAYLIST_VIDEO = gql`
  mutation DeletePlaylistVideo($input: DeletePlaylistVideoInput!) {
    deletePlaylistVideo(input: $input) {
      ...playlistVideoFields
    }
  }
  ${playlistVideoFieldsFragment}
`;
export const UPDATE_PLAYLIST_VIDEO = gql`
  mutation UpdatePlaylistVideo($input: UpdatePlaylistVideoInput!) {
    updatePlaylistVideo(input: $input) {
      ...playlistVideoFields
    }
  }
  ${playlistVideoFieldsFragment}
`;

export const CREATE_VIDEO_CATEGORY = gql`
  mutation CreateVideoCategory($input: CreateVideoCategoryInput!) {
    createVideoCategory(input: $input) {
      id
      video {
        id
      }
      category {
        ...categoryFields
        group {
          ...categoryGroupFields
        }
      }
    }
  }
  ${categoryFieldsFragment}
  ${categoryGroupFieldsFragment}
`;

export const DELETE_VIDEO_CATEGORY = gql`
  mutation ($input: DeleteVideoCategoryInput!) {
    deleteVideoCategory(input: $input) {
      id
      video {
        id
      }
    }
  }
`;
