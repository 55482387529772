import React from "react";
import { ListGroup, ListGroupItem } from "react-bootstrap";
import { useToggle } from "react-use";
import { PlaylistsSelector } from "./PlaylistsSelector";
import { ShareLinks } from "components/ShareLinks";

export const ContextMenu = ({ videoId, slug, playlistVideos }) => {
  const [showPlaylists, toggleShowPlaylists] = useToggle();

  if (showPlaylists) {
    return (
      <PlaylistsSelector videoId={videoId} playlistVideos={playlistVideos} />
    );
  }
  return (
    <ListGroup>
      <ListGroupItem action onClick={toggleShowPlaylists}>
        Add to playlist
      </ListGroupItem>
      <ListGroupItem>
        <ShareLinks slug={slug} />
      </ListGroupItem>
    </ListGroup>
  );
};
