import { ApolloProvider } from "@apollo/react-hooks";
import { InMemoryCache } from "apollo-cache-inmemory";
import ApolloClient from "apollo-client";
import { ApolloLink } from "apollo-link";
import Amplify, { Auth } from "aws-amplify";
import { createAuthLink } from "aws-appsync-auth-link";
import { createSubscriptionHandshakeLink } from "aws-appsync-subscription-link";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import aws_exports from "./aws-exports";
import "./index.css";
import { unregister } from "./serviceWorker";
import ReactGA from "react-ga";

let config = Amplify.configure(aws_exports);
let aws_cloud_logic_custom = config.aws_cloud_logic_custom.map((o) => {
  if (o.name === "AdminQueries") {
    return {
      ...o,
      async custom_header() {
        return {
          "Content-Type": "application/json",
          Authorization: `${(await Auth.currentSession())
            .getAccessToken()
            .getJwtToken()}`,
        };
      },
    };
  } else {
    return o;
  }
});

config = Amplify.configure({
  Analytics: {
    disabled: true,
  },
  aws_cloud_logic_custom,
});

const url = config.aws_appsync_graphqlEndpoint;
const region = config.aws_appsync_region;
const auth = {
  type: config.aws_appsync_authenticationType,
  jwtToken: async () =>
    (await Auth.currentSession()).getIdToken().getJwtToken(),
};

const link = ApolloLink.from([
  createAuthLink({ url, region, auth }),
  createSubscriptionHandshakeLink({ url, region, auth }),
]);
const client = new ApolloClient({
  link,
  defaultOptions: {
    watchQuery: {
      errorPolicy: "all",
    },
    query: {
      errorPolicy: "all",
    },
    mutate: {
      errorPolicy: "all",
    },
  },
  cache: new InMemoryCache({
    cacheRedirects: {
      Query: {
        playlistVideosByVideo: (_, args, { getCacheKey }) => {
          return getCacheKey({
            __typename: "$video",
            id: `${args.videoID}.playlistVideos`,
          });
        },
        playlistVideosByPlaylist: (_, args, { getCacheKey }) => {
          return getCacheKey({
            __typename: "$playlist",
            id: `${args.playlistID}.playlistVideos`,
          });
        },
      },
    },
  }),
});

ReactGA.initialize("UA-167570527-1", {});

ReactDOM.render(
  <ApolloProvider client={client}>
    <App />
  </ApolloProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
unregister();
