import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import IconButton from "components/IconButton";
import { saveAs } from "file-saver";
import Papa from "papaparse";
import React from "react";
import {
  Button,
  ButtonGroup,
  Col,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
export const ButtonRow = ({
  setShowCreateNewUserFormModal,
  openMailto,
  users,
}) => (
  <Row>
    <Col>
      <ButtonGroup>
        <IconButton
          variant="dark"
          onClick={() => setShowCreateNewUserFormModal(true)}
          icon="user-plus"
          tooltip="Add new user"
        />
      </ButtonGroup>
      <ButtonGroup>
        <OverlayTrigger
          overlay={<Tooltip>Send email to selected users</Tooltip>}
        >
          <Button
            onClick={openMailto}
            variant="dark"
            disabled={users.filter(o => o.selected).length === 0}
          >
            <FontAwesomeIcon icon="mail-bulk" />
          </Button>
        </OverlayTrigger>
        <IconButton
          variant="dark"
          disabled={users.filter(o => o.selected).length === 0}
          onClick={() => generateCSV(users)}
          icon="file-csv"
          tooltip="Download selected users as CSV file"
        />
      </ButtonGroup>
    </Col>
  </Row>
);

function generateCSV(users) {
  const selected_users = users
    .filter(o => o.selected)
    .map(
      ({
        username,
        email,
        company,
        createdDate,
        modifiedDate,
        enabled,
        status,
        admin,
        user,
      }) => ({
        username,
        email,
        company,
        createdDate,
        modifiedDate,
        enabled,
        status,
        admin,
        user,
      })
    );
  const csv = Papa.unparse(selected_users);
  const file = new File([csv], "Users.csv", {
    type: "text/csv;charset=utf-8",
  });
  saveAs(file);
}
