import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useRef } from "react";
import { Overlay, OverlayTrigger, Popover, Tooltip } from "react-bootstrap";
export const ShareLink = ({
  linkTarget,
  visible,
  toggleVisible,
  videoUrl,
  tooltip,
  title,
  icon,
}) => {
  const inputEl = useRef(null);
  const copyToClipboardCb = () => {
    navigator.clipboard.writeText(videoUrl);
    toggleVisible(false);
  };

  return (
    <div className="sharelink sharelink-videolist">
      <OverlayTrigger
        placement="bottom"
        overlay={<Tooltip>{tooltip || title}</Tooltip>}
      >
        <button ref={linkTarget} onClick={toggleVisible} className="btn-sm">
          <FontAwesomeIcon icon={icon} size="lg" />
        </button>
      </OverlayTrigger>
      <Overlay target={linkTarget} show={visible} placement="top">
        {({ show, ...props }) => {
          return (
            <Popover {...props} className="popup">
              <Popover.Content>
                <div className="link-share-box">
                  <h5 className="share-url-title">{title}</h5>
                  <button onClick={toggleVisible} className="close-share-box">
                    <FontAwesomeIcon icon="times" size="lg" />
                  </button>
                  <div className="link-share-group">
                    <input
                      ref={inputEl}
                      readOnly
                      className="share-url-field"
                      value={videoUrl}
                    />
                    <OverlayTrigger
                      placement="bottom"
                      overlay={<Tooltip>Click to copy URL</Tooltip>}
                    >
                      <button
                        onClick={copyToClipboardCb}
                        className="btn-copy-url"
                      >
                        <FontAwesomeIcon icon="copy" size="lg" />
                      </button>
                    </OverlayTrigger>
                  </div>
                </div>
              </Popover.Content>
            </Popover>
          );
        }}
      </Overlay>
    </div>
  );
};
