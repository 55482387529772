import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import React, { useRef } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useToggle } from "react-use";

const SearchBar = ({ searchPhrase, setSearchPhrase }) => {
  const [isFocused, toggleFocus] = useToggle(false);
  const inputEl = useRef();
  return (
    <>
      <div className="search-form">
        <input
          ref={inputEl}
          name="search"
          type="text"
          className="search-input"
          placeholder="search..."
          value={searchPhrase}
          autoComplete="off"
          onChange={e => setSearchPhrase(e.target.value.toLowerCase())}
        />
      </div>
      <OverlayTrigger overlay={<Tooltip>Search the library</Tooltip>}>
        <button
          className="action-cta search-cta btn-lg"
          onClick={() => {
            inputEl.current[isFocused ? "blur" : "focus"]();
            
            toggleFocus();
          }}
        >
          <FontAwesomeIcon icon="search" />
        </button>
      </OverlayTrigger>
    </>
  );
};

SearchBar.propTypes = {
  searchPhrase: PropTypes.string,
  setSearchPhrase: PropTypes.func,
};
export default SearchBar;
