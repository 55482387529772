import gql from "graphql-tag";
import {
  categoryFieldsFragment,
  categoryGroupFieldsFragment,
  playlistFieldsFragment,
  playlistVideoFieldsFragment,
  videoCategoriesFieldsFragment,
  videoFieldsFragment,
  videoSlugFieldsFragment,
} from "./fragments";

export const LIST_CATEGORY_GROUPS = gql`
  query ListCategoryGroups(
    $filter: ModelCategoryGroupFilterInput
    $groupLimit: Int
    $groupNextToken: String
    $categoryLimit: Int
    $categoryNextToken: String
  ) {
    listCategoryGroups(
      filter: $filter
      limit: $groupLimit
      nextToken: $groupNextToken
    ) @connection(key: "categoryGroups", filter: ["filter"]) {
      items {
        ...categoryGroupFields
        categories(limit: $categoryLimit, nextToken: $categoryNextToken)
          @connection(key: "categories", filter: []) {
          items {
            ...categoryFields
          }
          nextToken
          __typename
        }
      }
      nextToken
      __typename
    }
  }
  ${categoryGroupFieldsFragment}
  ${categoryFieldsFragment}
`;

export const LIST_CATEGORY_GROUPS_EXTENDED = gql`
  query ListCategoryGroups(
    $filter: ModelCategoryGroupFilterInput
    $groupLimit: Int
    $groupNextToken: String
    $categoryLimit: Int
    $categoryNextToken: String
    $videosLimit: Int
  ) {
    listCategoryGroups(
      filter: $filter
      limit: $groupLimit
      nextToken: $groupNextToken
    ) @connection(key: "categoryGroups", filter: ["filter"]) {
      items {
        ...categoryGroupFields
        categories(limit: $categoryLimit, nextToken: $categoryNextToken)
          @connection(key: "categories", filter: []) {
          items {
            ...categoryFields
            videos(limit: $videosLimit) {
              items {
                id
              }
            }
          }
          nextToken
          __typename
        }
      }
      nextToken
      __typename
    }
  }
  ${categoryGroupFieldsFragment}
  ${categoryFieldsFragment}
`;

export const LIST_CATEGORIES = gql`
  query ListCategorys(
    $filter: ModelcategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCategorys(filter: $filter, limit: $limit, nextToken: $nextToken)
      @connection(key: "categories", filter: ["filter"]) {
      items {
        ...categoryFields
        group {
          ...categoryGroupsFields
        }
      }
      nextToken
      __typename
    }
  }
  ${categoryGroupFieldsFragment}
  ${categoryFieldsFragment}
`;

export const GET_VIDEO = gql`
  query GetVideo(
    $id: ID!
    $playlistVideosLimit: Int
    $playlistVideosNextToken: String
    $categoriesLimit: Int
    $categoriesNextToken: String
  ) {
    getVideo(id: $id) {
      ...videoFields
      slug {
        ...videoSlugFields
      }
      categories(limit: $categoriesLimit, nextToken: $categoriesNextToken)
        @connection(key: "categories", filter: []) {
        items {
          ...videoCategoriesFields
        }
        nextToken
        __typename
      }
      playlistVideos(
        limit: $playlistVideosLimit
        nextToken: $playlistVideosNextToken
      ) @connection(key: "playlistVideos", filter: []) {
        items {
          ...playlistVideoFields
          playlist {
            ...playlistFields
          }
        }
        nextToken
        __typename
      }
    }
  }
  ${videoSlugFieldsFragment}
  ${videoFieldsFragment}
  ${videoCategoriesFieldsFragment}
  ${playlistVideoFieldsFragment}
  ${playlistFieldsFragment}
`;

export const GET_VIDEO_BY_SLUG = gql`
  query GetVideoSlug(
    $slug: ID!
    $playlistVideosLimit: Int
    $playlistVideosNextToken: String
    $categoriesLimit: Int
    $categoriesNextToken: String
  ) {
    getVideoSlug(slug: $slug) {
      video {
        ...videoFields
        slug {
          ...videoSlugFields
        }
        categories(limit: $categoriesLimit, nextToken: $categoriesNextToken)
          @connection(key: "categories", filter: []) {
          items {
            ...videoCategoriesFields
          }
          nextToken
          __typename
        }
        playlistVideos(
          limit: $playlistVideosLimit
          nextToken: $playlistVideosNextToken
        ) @connection(key: "playlistVideos", filter: []) {
          items {
            ...playlistVideoFields
            playlist {
              ...playlistFields
            }
          }
          nextToken
          __typename
        }
      }
      ...videoSlugFields
      __typename
    }
  }
  ${videoSlugFieldsFragment}
  ${videoFieldsFragment}
  ${videoCategoriesFieldsFragment}
  ${playlistVideoFieldsFragment}
  ${playlistFieldsFragment}
`;

export const LIST_VIDEOS = gql`
  query ListVideos(
    $filter: ModelVideoFilterInput
    $limit: Int
    $nextToken: String
    $playlistVideosLimit: Int
    $playlistVideosNextToken: String
    $categoriesLimit: Int
    $categoriesNextToken: String
  ) {
    listVideos(filter: $filter, limit: $limit, nextToken: $nextToken)
      @connection(key: "videos", filter: ["filter"]) {
      items {
        ...videoFields
        categories(limit: $categoriesLimit, nextToken: $categoriesNextToken)
          @connection(key: "categories", filter: []) {
          items {
            ...videoCategoriesFields
          }
          nextToken
          __typename
        }
        playlistVideos(
          limit: $playlistVideosLimit
          nextToken: $playlistVideosNextToken
        ) @connection(key: "playlistVideos", filter: []) {
          items {
            ...playlistVideoFields

            playlist {
              ...playlistFields
            }
          }
          nextToken
          __typename
        }
        slug {
          ...videoSlugFields
        }
      }
      nextToken
      __typename
    }
  }
  ${videoFieldsFragment}
  ${videoSlugFieldsFragment}
  ${videoCategoriesFieldsFragment}
  ${playlistVideoFieldsFragment}
  ${playlistFieldsFragment}
`;

export const LIST_VIDEOS_ONLY = gql`
  query ListVideos(
    $filter: ModelVideoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVideos(filter: $filter, limit: $limit, nextToken: $nextToken)
      @connection(key: "videos", filter: ["filter"]) {
      items {
        ...videoFields
      }
      nextToken
      __typename
    }
  }
  ${videoFieldsFragment}
`;

export const LIST_VIDEO_SLUGS_BY_VIDEO = gql`
  query ListVideoSlugsByVideo(
    $videoID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelvideoSlugFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVideoSlugsByBase(
      videoID: $videoID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        ...videoSlugFields
      }
      nextToken
    }
  }
  ${videoSlugFieldsFragment}
`;

export const LIST_VIDEO_SLUGS_BY_BASE = gql`
  query ListVideoSlugsByBase(
    $slugBase: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelvideoSlugFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVideoSlugsByBase(
      slugBase: $slugBase
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        ...videoSlugFields
      }
      nextToken
    }
  }
  ${videoSlugFieldsFragment}
`;

export const GET_PLAYLIST = gql`
  query GetPlaylist($id: ID!) {
    getPlaylist(id: $id) {
      ...playlistFields
    }
  }
  ${playlistFieldsFragment}
`;

export const LIST_PLAYLISTS = gql`
  query ListPlaylists(
    $filter: ModelPlaylistFilterInput
    $limit: Int
    $nextToken: String
    $playlistVideosLimit: Int
    $playlistVideosNextToken: String
  ) {
    listPlaylists(filter: $filter, limit: $limit, nextToken: $nextToken)
      @connection(key: "playlists", filter: ["filter"]) {
      items {
        ...playlistFields

        playlistVideos(
          limit: $playlistVideosLimit
          nextToken: $playlistVideosNextToken
        ) @connection(key: "playlistVideos", filter: []) {
          items {
            ...playlistVideoFields

            video {
              id
              __typename
            }
          }
          nextToken
          __typename
        }
      }
      nextToken
      __typename
    }
  }
  ${playlistFieldsFragment}
  ${playlistVideoFieldsFragment}
`;

export const LIST_PLAYLISTS_ONLY = gql`
  query ListPlaylists(
    $filter: ModelPlaylistFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPlaylists(filter: $filter, limit: $limit, nextToken: $nextToken)
      @connection(key: "playlists", filter: ["filter"]) {
      items {
        ...playlistFields
      }
      nextToken
      __typename
    }
  }
  ${playlistFieldsFragment}
`;

export const LIST_PLAYLIST_VIDEOS = gql`
  query ListPlaylistVideos(
    $filter: ModelplaylistVideoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPlaylistVideos(filter: $filter, limit: $limit, nextToken: $nextToken)
      @connection(key: "playlistVideos", filter: ["filter"]) {
      items {
        ...playlistVideoFields

        video {
          ...videoFields
        }
      }
      nextToken
      __typename
    }
  }
  ${videoFieldsFragment}
  ${playlistVideoFieldsFragment}
`;

export const LIST_PLAYLIST_VIDEOS_BY_PLAYLIST = gql`
  query PlaylistVideosByPlaylist(
    $playlistID: ID!
    $limit: Int
    $nextToken: String
    $playlistVideosLimit: Int
    $playlistVideosNextToken: String
  ) {
    playlistVideosByPlaylist(
      playlistID: $playlistID
      limit: $limit
      nextToken: $nextToken
    ) @connection(key: "playlistVideos", filter: ["playlistID"]) {
      items {
        ...playlistVideoFields

        video {
          ...videoFields
          playlistVideos(
            limit: $playlistVideosLimit
            nextToken: $playlistVideosNextToken
          ) @connection(key: "playlistVideos", filter: []) {
            items {
              ...playlistVideoFields
              playlist {
                ...playlistFields
              }
            }
            nextToken
            __typename
          }
        }
      }
      nextToken
      __typename
    }
  }
  ${videoFieldsFragment}
  ${playlistVideoFieldsFragment}
  ${playlistFieldsFragment}
`;

export const LIST_PLAYLIST_VIDEOS_BY_VIDEO = gql`
  query PlaylistVideosByVideo($videoID: ID!, $limit: Int, $nextToken: String) {
    playlistVideosByVideo(
      videoID: $videoID
      limit: $limit
      nextToken: $nextToken
    ) @connection(key: "playlistVideos", filter: ["videoID"]) {
      items {
        ...playlistVideoFields

        playlist {
          ...playlistFields
        }
      }
      nextToken
      __typename
    }
  }
  ${playlistFieldsFragment}
  ${playlistVideoFieldsFragment}
`;
