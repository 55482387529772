import {
  addUser,
  addUserToGroup,
  deleteUser,
  removeUserFromGroup,
  resetUserPassword,
  updateUserAttributes,
} from "helpers/adminQueries";
import update from "immutability-helper";
import React, { useCallback, useEffect, useState } from "react";
import { Container, Table } from "react-bootstrap";
import { useKeyPress, useKeyPressEvent, useList } from "react-use";
import useToggle from "react-use/lib/useToggle";
import { ButtonRow } from "./ButtonRow";
import { CreateNewUserFormModal } from "./CreateNewUserFormModal";
import { UserTableHeader } from "./UserTableHeader";
import { UserTableRow } from "./UserTableRow";

export function UserTable({ users: rawUsers, reloadUsers }) {
  const [users, updateUsers] = useList(
    rawUsers.map((user, idx) => ({
      selected: false,
      username: user.Username,
      email: user.Attributes.email,
      status: user.UserStatus,
      company: user.Attributes["custom:company_name"],
      terms: user.Attributes["custom:terms_accepted"] === "true",
      admin: !!user.groups.Admin,
      user: !!user.groups.User,
      createdDate: user.UserCreateDate,
      modifiedDate: user.UserLastModifiedDate,
      enabled: user.Enabled,
      original_index: idx,
    }))
  );

  const [showCreateNewUserFormModal, setShowCreateNewUserFormModal] =
    useState(false);

  const [selectAll, toggleSelectAllBool] = useToggle(false);
  const [isShiftPressed] = useKeyPress("Shift");
  const [lastSelectedUserIndex, setLastSelectedUserIndex] = useState(null);
  const [shiftStartSelectedUserIndex, setShiftStartSelectedUserIndex] =
    useState(null);

  useKeyPressEvent(
    "Shift",
    () => setShiftStartSelectedUserIndex(lastSelectedUserIndex),
    () => {
      setShiftStartSelectedUserIndex(null);
      setLastSelectedUserIndex(null);
    }
  );
  const [currentSort, setCurrentSort] = useState({
    key: "email",
    state: "default",
    sorted: false,
  });
  const initialSortColumns = {
    email: "default",
    status: "default",
    company: "default",
    user: "default",
    admin: "default",
    terms: "default",
  };
  const [sortColumns, setSortColumns] = useState(initialSortColumns);

  const sortFunctions = {
    email: (a, b) => {
      return (a.email || "").localeCompare(b.email || "");
    },
    status: (a, b) => (a.status || "").localeCompare(b.status || ""),
    company: (a, b) => (a.company || "").localeCompare(b.company || ""),
    user: (a, b) => a.user - b.user,
    admin: (a, b) => a.admin - b.admin,
    terms: (a, b) => a.terms - b.terms,
  };

  useEffect(() => {
    if (currentSort.sorted) {
      return;
    }
    let fn = sortFunctions[currentSort.key];
    if (currentSort.state === "default") {
      fn = (a, b) => a.original_index - b.original_index;
    }

    updateUsers.sort(fn);
    if (currentSort.state === "desc") {
      updateUsers.set((curr) => curr.slice().reverse());
    }
    setCurrentSort({ ...currentSort, sorted: true });
  }, [currentSort, sortFunctions, updateUsers]);

  const setSortColumn = useCallback(
    (key) => {
      let state = sortColumns[key];
      if (state === "default") {
        state = "asc";
      } else if (state === "asc") {
        state = "desc";
      } else {
        state = "default";
      }
      setSortColumns({ ...initialSortColumns, [key]: state });
      setCurrentSort({ key, state, sorted: false });
    },
    [initialSortColumns, sortColumns]
  );

  const selectUsers = (selected) =>
    updateUsers.set(update(users, (o) => o.map((i) => ({ ...i, selected }))));

  const selectUser = (index, selected) => {
    if (isShiftPressed && shiftStartSelectedUserIndex == null) {
      setShiftStartSelectedUserIndex(index);
    }
    if (isShiftPressed && shiftStartSelectedUserIndex != null) {
      updateUsers.set(
        update(users, (o) =>
          o.map((i, ix) => {
            if (
              ix >= Math.min(index, shiftStartSelectedUserIndex) &&
              ix <= Math.max(index, shiftStartSelectedUserIndex)
            ) {
              return { ...i, selected };
            } else {
              return i;
            }
          })
        )
      );
    } else {
      const toUpdate = {
        [index]: {
          selected: { $set: selected },
        },
      };
      updateUsers.set(update(users, toUpdate));
    }
    setLastSelectedUserIndex(index);
  };

  const toggleSelectAll = () => {
    toggleSelectAllBool();
    selectUsers(!selectAll);
  };

  const createUserCb = async ({ email, company }) => {
    await addUser(email, {
      email: email,
      email_verified: "true",
      "custom:company_name": company,
    });
    reloadUsers();
  };
  const updateUserCb = async ({username, email, company }) => {
    await updateUserAttributes(
      username, {
        email,
        email_verified: "true",
        "custom:company_name": company,
      }
    )
    reloadUsers();
  };

  const toggleTermsCb = async (u1, verified) => {
    const idx = users.findIndex((u2) => u1.email === u2.email);
    updateUsers.set(
      update(users, {
        [idx]: { terms: { $set: undefined } },
      })
    );
    await updateUserAttributes(u1.email, {
      "custom:terms_accepted": verified ? "true" : "false",
    });
    updateUsers.set(
      update(users, {
        [idx]: { terms: { $set: verified } },
      })
    );
  };
  const deleteUserCb = async (u1) => {
    await deleteUser(u1.email);
    updateUsers.removeAt(users.findIndex((u2) => u1.email === u2.email));
  };

  const resetPasswordCb = async (u1) => {
    await resetUserPassword(u1.email);
    const idx = users.findIndex((u2) => u1.email === u2.email);

    updateUsers.set(
      update(users, {
        [idx]: { status: { $set: "RESET_REQUIRED" } },
      })
    );
  };

  const toggleGroupCb = async (u1, group) => {
    const lcGroup = group.toLowerCase();
    const idx = users.findIndex((u2) => u1.email === u2.email);
    updateUsers.set(
      update(users, {
        [idx]: { $unset: [lcGroup] },
      })
    );
    const willRemove = u1[lcGroup];
    if (willRemove) {
      await removeUserFromGroup(u1.email, group);
    } else {
      await addUserToGroup(u1.email, group);
    }
    updateUsers.set(
      update(users, {
        [idx]: { [lcGroup]: { $set: !willRemove } },
      })
    );
  };

  const openMailtoCb = () => {
    const emails = users.filter((o) => o.selected).map(({ email }) => email);
    window.location.href = `mailto:${emails.join(",")}`;
  };

  return (
    <Container className="Users">
      <CreateNewUserFormModal
        show={showCreateNewUserFormModal}
        onSubmit={(data) => {
          createUserCb(data);
          setShowCreateNewUserFormModal(false);
        }}
        onCancel={(data) => {
          setShowCreateNewUserFormModal(false);
        }}
      />
      <ButtonRow
        setShowCreateNewUserFormModal={setShowCreateNewUserFormModal}
        openMailto={openMailtoCb}
        users={users}
      />
      <Table striped hover>
        <thead>
          <UserTableHeader
            selectAll={selectAll}
            toggleSelectAll={toggleSelectAll}
            sortColumns={sortColumns}
            setSortColumn={setSortColumn}
          />
        </thead>
        <tbody>
          {users.map((user, index) => {
            return (
              <UserTableRow
                key={`userrow-${index}`}
                user={user}
                selectUser={selectUser}
                index={index}
                toggleGroupCb={toggleGroupCb}
                deleteUserCb={deleteUserCb}
                resetPasswordCb={resetPasswordCb}
                toggleTermsCb={toggleTermsCb}
                updateUserCb={updateUserCb}
              />
            );
          })}
        </tbody>
      </Table>
    </Container>
  );
}
