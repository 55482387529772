import { Storage } from "aws-amplify";
import { GQL_LIMIT } from "constants.js";
import { GET_VIDEO_BY_SLUG } from "graphql/custom/queries";
import React, { useEffect, useRef, useState } from "react";
import { useQuery } from "react-apollo";
import { useLocation } from "react-use";
import { ShareLink } from "./ShareLink";
export const ShareLinks = ({ slug }) => {
  let { href } = useLocation();

  const [publicVideoUrl, setPublicVideoUrl] = useState("");
  // Ensure correct URL format to eliminate doubling videoId for each copy
  const baseurl = `${href}`.indexOf("/v")===0 ? `${href}/v` : `${href}`.split("/v")[0]+"/v";

  const internalUrl = baseurl + `/${slug}`;
  const [showPublicSharelink, setShowPublicSharelink] = useState(false);
  const [showInternalSharelink, setShowInternalSharelink] = useState(false);

  const showPublicSharelinkTarget = useRef(null);
  const showInternalSharelinkTarget = useRef(null);

  const toggleShowPublicSharelink = () => {
    setShowInternalSharelink(false);
    setShowPublicSharelink(!showPublicSharelink);
  };
  const toggleShowInternalSharelink = () => {
    setShowPublicSharelink(false);
    setShowInternalSharelink(!showInternalSharelink);
  };
  const { loading, error, data } = useQuery(GET_VIDEO_BY_SLUG, {
    variables: {
      slug,
      categoriesLimit: GQL_LIMIT,
      playlistVideosLimit: GQL_LIMIT,
    },
  });
  useEffect(() => {
    const fetchData = async () => {
      if (data && data.getVideoSlug && data.getVideoSlug.video.mp4) {
        const {
          AWSS3: { bucket, region },
        } = Storage.vault.configure();
        setPublicVideoUrl(
          `https://${bucket}.s3.${region}.amazonaws.com/public/${data.getVideoSlug.video.mp4}`
        );
      }
    };
    fetchData();
  }, [data]);
  if (loading) {
    return "Loading...";
  }

  if (error) {
    console.warn(error.message);
  }

  const { public: isPublic } = data.getVideoSlug.video;
  return (
    <>
      {isPublic && (
        <ShareLink
          videoUrl={publicVideoUrl}
          visible={showPublicSharelink}
          linkTarget={showPublicSharelinkTarget}
          toggleVisible={toggleShowPublicSharelink}
          title="Share public video link"
          icon="share-square"
        />
      )}
      <ShareLink
        videoUrl={internalUrl}
        visible={showInternalSharelink}
        linkTarget={showInternalSharelinkTarget}
        toggleVisible={toggleShowInternalSharelink}
        title="Share internal video link"
        icon="share-alt"
      />
    </>
  );
};
