import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GQL_LIMIT } from "constants.js";
import { CREATE_CATEGORY_GROUP } from "graphql/custom/mutations";
import { LIST_CATEGORY_GROUPS_EXTENDED } from "graphql/custom/queries";
import get from "lodash/get";
import React, { useCallback } from "react";
import { useMutation, useQuery } from "react-apollo";
import { Button, Container, OverlayTrigger, Tooltip } from "react-bootstrap";
import { CategoryGroupList } from "./CategoryGroupList";

const Tags = props => {
  const categoryGroupsQuery = useQuery(LIST_CATEGORY_GROUPS_EXTENDED, {
    variables: {
      groupLimit: GQL_LIMIT,
      categoryLimit: GQL_LIMIT,
      videosLimit: GQL_LIMIT,
    },
  });

  const [createCategoryGroup] = useMutation(CREATE_CATEGORY_GROUP, {
    refetchQueries: ["ListCategoryGroups"],
  });

  const onCreateCategoryGroup = useCallback(async () => {
    const categoryGroups = get(
      categoryGroupsQuery,
      "data.listCategoryGroups.items"
    );
    if (categoryGroups) {
      createCategoryGroup({
        variables: { input: { order: categoryGroups.length } },
      });
    }
  }, [categoryGroupsQuery, createCategoryGroup]);

  if (categoryGroupsQuery.loading) {
    return "Loading...";
  }
  if (categoryGroupsQuery.error) {
    console.warn(categoryGroupsQuery.error.message);
  }

  const categoryGroups = categoryGroupsQuery.data.listCategoryGroups.items
    .map(grp => ({
      id: grp.id,
      label: grp.label,
      order: grp.order,
      categories: grp.categories.items
        .map(cat => ({
          id: cat.id,
          label: cat.label,
          href: cat.href,
          order: cat.order,
          videos: cat.videos.items,
        }))
        .sort(
          (a, b) => (b.order != null) - (a.order != null) || a.order - b.order
        ),
    }))
    .sort((a, b) => (b.order != null) - (a.order != null) || a.order - b.order);

  return (
    <Container>
      <CategoryGroupList groups={categoryGroups} />

      <OverlayTrigger overlay={<Tooltip>Create new category group!</Tooltip>}>
        <Button variant="link" onClick={onCreateCategoryGroup}>
          <FontAwesomeIcon icon="plus-circle" color="green" />
        </Button>
      </OverlayTrigger>
    </Container>
  );
};

export default Tags;
