import React from "react";
import { Form } from "react-bootstrap";
export const Header = ({ children, publicOnly, setPublicOnly }) => (
  <header className="main">
    <div className="d-flex justify-content-between align-items-center">
      <h1 className="favorites-header active-header">
        DPAplay - M-Series Video Library
      </h1>
      <div className="corner-logo" />
    </div>
    {children}
    <div className="d-flex justify-content-between align-items-center">
      <Form.Group controlId="publicOnly">
        <Form.Check
          type="checkbox"
          label="Show only public videos"
          checked={publicOnly}
          onChange={e => setPublicOnly(e.target.checked)}
        ></Form.Check>
      </Form.Group>
    </div>
  </header>
);
