import React from "react";
import { FormCheck } from "react-bootstrap";
import { TableHeader } from "./TableHeader";
export const UserTableHeader = ({
  selectAll,
  toggleSelectAll,
  sortColumns,
  setSortColumn,
}) => (
  <tr>
    <th>
      <FormCheck
        aria-label="Toggle All"
        checked={selectAll}
        onChange={toggleSelectAll}
      />
    </th>
    <TableHeader
      label="Email"
      order={sortColumns.email}
      onClick={() => setSortColumn("email")}
    />
    <TableHeader
      label="Status"
      order={sortColumns.status}
      onClick={() => setSortColumn("status")}
    />
    <TableHeader
      label="Company"
      order={sortColumns.company}
      onClick={() => setSortColumn("company")}
    />
    <TableHeader
      label="Admin"
      order={sortColumns.admin}
      onClick={() => setSortColumn("admin")}
    />
    <TableHeader
      label="User"
      order={sortColumns.user}
      onClick={() => setSortColumn("user")}
    />
    <TableHeader
      label="Terms"
      order={sortColumns.terms}
      onClick={() => setSortColumn("terms")}
    />
    <th></th>
  </tr>
);
