import { Formik } from "formik";
import PropTypes from "prop-types";
import React from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import * as yup from "yup";

const schema = yup.object({
  email: yup
    .string()
    .email()
    .required(),
  company: yup.string().required(),
});

export const CreateNewUserFormModal = ({ show, onCancel, onSubmit }) => {
  return (
    <Formik
      validationSchema={schema}
      onSubmit={onSubmit}
      initialValues={{
        email: "",
        company: "",
      }}
    >
      {({
        isSubmitting,
        handleSubmit,
        handleChange,
        handleReset,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
      }) => (
        <Modal
          show={show}
          onHide={onCancel}
          onExited={handleReset}
          size="lg"
          dialogClassName="UserModal"
        >
          <Form noValidate onSubmit={handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title>Create new user</Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <Form.Group as={Row} controlId="email">
                <Form.Label column sm={2}>
                  Email
                </Form.Label>
                <Col sm={10}>
                  <Form.Control
                    type="text"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={!!errors.email && touched.email}
                  />
                  <Form.Text className="text-muted">
                    Note: The email address is case sensitive when logging in!
                  </Form.Text>
                  <Form.Control.Feedback type="invalid">
                    {errors.email}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="company">
                <Form.Label column sm={2}>
                  Company
                </Form.Label>
                <Col sm={10}>
                  <Form.Control
                    type="text"
                    name="company"
                    value={values.company}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={!!errors.company && touched.company}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.company}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
            </Modal.Body>

            <Modal.Footer>
              <Button variant="secondary" onClick={onCancel}>
                Cancel
              </Button>
              <Button variant="primary" type="submit" disabled={isSubmitting}>
                Create
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      )}
    </Formik>
  );
};

CreateNewUserFormModal.propTypes = {
  show: PropTypes.bool,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
};

CreateNewUserFormModal.defaultProps = {
  show: false,
  onSubmit: () => {},
  onCancel: () => {},
};
