import React from "react";
import { SortButton } from "./SortButton";
export const TableHeader = ({ label, order, onClick }) => (
  <th className="sortable-header" onClick={onClick}>
    <div className=" d-flex justify-content-between">
      {label}
      <SortButton order={order} />
    </div>
  </th>
);
