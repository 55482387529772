import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GQL_LIMIT } from "constants.js";
import {
  GET_PLAYLIST,
  LIST_PLAYLIST_VIDEOS_BY_PLAYLIST
} from "graphql/custom/queries";
import PropTypes from "prop-types";
import React from "react";
import { useQuery } from "react-apollo";
import { Button } from "react-bootstrap";
import { Link, useHistory, useParams } from "react-router-dom";
import { useSessionStorage } from "react-use";
import VideoListBase from "./base";
import { Header } from "./Header";

export const VideoListFromPlaylist = params => {
  const [publicOnly, setPublicOnly] = useSessionStorage("publicOnly", false);

  const history = useHistory();
  const { playlistId } = useParams();
  const playlist = useQuery(GET_PLAYLIST, {
    variables: { id: playlistId },
  });
  const videos = useQuery(LIST_PLAYLIST_VIDEOS_BY_PLAYLIST, {
    variables: {
      playlistID: playlistId,
      limit: GQL_LIMIT,
      playlistVideosLimit: GQL_LIMIT,
    },
  });

  if (playlist.loading || videos.loading) {
    return "Loading...";
  }
  if (playlist.error) {
    console.warn(playlist.error.message);
  }
  if (videos.error) {
    console.warn(videos.error.message);
  }

  if (playlist.data.getPlaylist == null) {
    history.replace("/v");
    return;
  }
  let items = videos.data.playlistVideosByPlaylist.items.map(
    item => item.video
  );

  if (publicOnly) {
    items = items.filter(item => item.public);
  }
  return (
    <>
      <div className="archive-content-container">
        <div className="archive-home active-container archive-playlist">
          <VideoListBase
            {...params}
            videoItems={items}
            refreshVideos={videos.refresh}
          />
        </div>
      </div>
      <Header publicOnly={publicOnly} setPublicOnly={setPublicOnly}>
        <div className="d-flex justify-content-between align-items-center">
          <h2 className="playlist-name-header">
            {playlist.data.getPlaylist.title}
          </h2>
          <Link to="/v" className="playlist-back-link">
            <Button>
              <FontAwesomeIcon icon="arrow-left" /> Back to main library
            </Button>
          </Link>
        </div>
      </Header>
    </>
  );
};

VideoListFromPlaylist.propTypes = {
  searchPhrase: PropTypes.string,
  filterOptions: PropTypes.array,
  sortOrder: PropTypes.object,
};
