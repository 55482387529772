import {
  DELETE_CATEGORY_GROUP,
  UPDATE_CATEGORY_GROUP,
  DELETE_CATEGORY,
  DELETE_VIDEO_CATEGORY,
} from "graphql/custom/mutations";
import PropTypes from "prop-types";
import React, { useCallback } from "react";
import { useMutation } from "react-apollo";
import { Card } from "react-bootstrap";
import { CategoryGroupForm } from "./CategoryGroupForm";
import { CategoryList } from "./CategoryList";

export const CategoryGroupList = ({ groups }) => {
  const [updateCategoryGroup] = useMutation(UPDATE_CATEGORY_GROUP);

  const [deleteCategoryGroup] = useMutation(DELETE_CATEGORY_GROUP, {
    refetchQueries: ["ListCategoryGroups"],
  });
  const [deleteCategory] = useMutation(DELETE_CATEGORY, {
    refetchQueries: ["ListCategoryGroups"],
  });
  const [deleteVideoCategory] = useMutation(DELETE_VIDEO_CATEGORY, {
    refetchQueries: ["ListCategoryGroups"],
  });
  const onDeleteCategoryGroup = useCallback(
    (group, groups, index) => async () => {
      let mutations = [
        ...group.categories.flatMap(category => {
          return [
            deleteCategory({ variables: { input: { id: category.id } } }),
            category.videos.map(videoCategory =>
              deleteVideoCategory({
                variables: { input: { id: videoCategory.id } },
              })
            ),
          ];
        }),
        deleteCategoryGroup({
          variables: { input: { id: group.id } },
        }),
      ];
      if (group.order != null) {
        mutations = mutations.concat(
          groups
            .map(({ id }, i) => ({
              variables: {
                input: { id, order: i - 1 },
              },
            }))
            .slice(index + 1)
            .map(updateCategoryGroup)
        );
      }
      return Promise.all(mutations);
    },
    [deleteCategory, deleteCategoryGroup, deleteVideoCategory, updateCategoryGroup]
  );
  const onMoveCategoryGroup = useCallback(
    (group, groups, index) => async dir => {
      if (
        (dir === "up" && index === 0) ||
        (dir === "down" && index === groups.length - 1)
      ) {
        return;
      }

      const target_index = dir === "up" ? index - 1 : index + 1;
      const target_group = groups[target_index];
      return Promise.all([
        updateCategoryGroup({
          variables: { input: { id: group.id, order: target_index } },
        }),
        updateCategoryGroup({
          variables: { input: { id: target_group.id, order: index } },
        }),
      ]);
    },
    [updateCategoryGroup]
  );

  return groups.map((group, index) => (
    <Card key={group.id}>
      <Card.Body>
        <CategoryGroupForm
          group={group}
          onDelete={onDeleteCategoryGroup(group, groups, index)}
          onMove={onMoveCategoryGroup(group, groups, index)}
        >
          <CategoryList categories={group.categories} />
        </CategoryGroupForm>
      </Card.Body>
    </Card>
  ));
};

CategoryGroupList.propTypes = {
  groups: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      categories: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
        })
      ).isRequired,
    })
  ).isRequired,
};
