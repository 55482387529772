import { listUserGroups } from "helpers/adminQueries";
import React from "react";
import { useAsyncRetry } from "react-use";
import "./Users.scss";
import { UserTable } from "./UserTable";

const Users = props => {
  const users = useAsyncRetry(async () => {
    const userGroups = await listUserGroups();
    return userGroups.map((user, index) => ({
      ...user,
      selected: false,
      original_index: index,
    }));
  });

  if (users.loading) {
    return "Loading...";
  }

  if (users.error) {
    console.warn(users.error.message);
  }
  return <UserTable users={users.value} reloadUsers={users.retry} />;
};
Users.propTypes = {};

Users.defaultProps = {};

export default Users;
