import {
  DELETE_CATEGORY,
  DELETE_VIDEO_CATEGORY,
  UPDATE_CATEGORY,
} from "graphql/custom/mutations";
import PropTypes from "prop-types";
import React, { useCallback } from "react";
import { useMutation } from "react-apollo";
import { CategoryForm } from "./CategoryForm";

export const CategoryList = ({ categories }) => {
  const [updateCategory] = useMutation(UPDATE_CATEGORY);

  const [deleteCategory] = useMutation(DELETE_CATEGORY, {
    refetchQueries: ["ListCategoryGroups"],
  });
  const [deleteVideoCategory] = useMutation(DELETE_VIDEO_CATEGORY, {
    refetchQueries: ["ListCategoryGroups"],
  });
  const onDeleteCategory = useCallback(
    (category, categories, index) => async () => {
      let mutations = [
        ...category.videos.map(videoCategory =>
          deleteVideoCategory({
            variables: { input: { id: videoCategory.id } },
          })
        ),
        deleteCategory({
          variables: { input: { id: category.id } },
        }),
      ];
      if (category.order != null) {
        mutations = mutations.concat(
          categories
            .map(({ id }, i) => ({
              variables: {
                input: { id: id, order: i - 1 },
              },
            }))
            .slice(index + 1)
            .map(updateCategory)
        );
      }
      return Promise.all(mutations);
    },
    [deleteCategory, deleteVideoCategory, updateCategory]
  );
  const onMoveCategory = useCallback(
    (category, categories, index) => async dir => {
      if (
        (dir === "up" && index === 0) ||
        (dir === "down" && index === categories.length - 1)
      ) {
        return;
      }

      const target_index = dir === "up" ? index - 1 : index + 1;
      const target_category = categories[target_index];
      return Promise.all([
        updateCategory({
          variables: { input: { id: category.id, order: target_index } },
        }),
        updateCategory({
          variables: { input: { id: target_category.id, order: index } },
        }),
      ]);
    },
    [updateCategory]
  );
  return categories.map((category, index) => (
    <CategoryForm
      key={category.id}
      category={category}
      onDelete={onDeleteCategory(category, categories, index)}
      onMove={onMoveCategory(category, categories, index)}
    />
  ));
};

CategoryList.propTypes = {
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    })
  ).isRequired,
};
