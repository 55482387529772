import { AuthTerms } from "AuthTerms";
import React, { useState } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import AddVideo from "./components/AddVideo";
import { Playlist } from "./components/Playlists/Playlist";
import Sidebar from "./components/Sidebar";
import VideoList from "./components/VideoList";
import { VideoListFromPlaylist } from "./components/VideoList/fromPlaylist";
import VideoPlayer from "./components/VideoPlayer";

const HomePage = props => {
  const [searchPhrase, setSearchPhrase] = useState("");
  const [filterOptions, setFilterOptions] = useState(null);
  const [sortOrder, setSortOrder] = useState({
    field: "createdAt",
    order: "DESC",
  });

  return (
    <div>
      <Switch>
        <Route exact path="/v/playlist/:playlistId">
          <VideoListFromPlaylist
            searchPhrase={searchPhrase}
            filterOptions={filterOptions}
            sortOrder={sortOrder}
          />
        </Route>
        <Route>
          <VideoList
            searchPhrase={searchPhrase}
            filterOptions={filterOptions}
            sortOrder={sortOrder}
          />
        </Route>
      </Switch>

      <Switch>
        <Route path="/v/:slug/edit">
          <div className="flyin-action-overlay visible" />
          <div className="flyin-action-area flyin-active">
            <div className="corner-logo-diamond"></div>
            <AddVideo mode="edit" />
          </div>
        </Route>
        <Route path="/v/playlist/:playlistId/edit">
          <div className="flyin-action-overlay visible" />
          <div className="flyin-action-area flyin-active">
            <div className="corner-logo-diamond"></div>
            <Playlist />
          </div>
        </Route>
        <Route path={"/v/new"}>
          <div className="flyin-action-overlay visible" />
          <div className="flyin-action-area flyin-active">
            <div className="corner-logo-diamond"></div>
            <AddVideo mode="create" />
          </div>
        </Route>
        <Route exact path={["/v/:slug", "/v/playlist/:playlistId/:slug"]}>
          <VideoPlayer />
        </Route>
        <Route path="/v">
          <div className="flyin-action-overlay" />
          <div className="flyin-action-area">
            <div className="corner-logo-diamond"></div>
            <Sidebar
              {...{
                searchPhrase,
                setSearchPhrase,
                filterOptions,
                setFilterOptions,
                sortOrder,
                setSortOrder,
              }}
            />
          </div>
        </Route>
      </Switch>
      <AuthTerms />
    </div>
  );
};

export default withRouter(HomePage);
