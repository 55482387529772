import React from "react";
import { Container, Row, Col, Button, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const TermsAndConditions = () => (
  <Container className="TermsAndConditions">
    <Row className="top-back-nav">
      <Col className="backButton">
        <Link to="/v">
          <Button type="button" variant="outline-light">
            <FontAwesomeIcon icon="arrow-left" />
            &nbsp;&nbsp;Back to video library
          </Button>
        </Link>
      </Col>
    </Row>

    <Row>
      <Col>
        <Card bg="dark" text="light" border="danger">
          <Card.Header>Terms and Conditions</Card.Header>
          <Card.Body>
            <Card.Title>PLEASE READ THESE TERMS AND CONDITIONS CAREFULLY BEFORE USING THIS SITE</Card.Title>
            <Card.Text>Last updated: (2020-04-14)</Card.Text>
            <Card.Text>
              <i><a href="https://ww.dpaplay.com" target="_blank" rel="noopener noreferrer">www.dpaplay.com</a> is a site operated by Domino Print and Apply AB (&quot;We&quot;). To contact us, please email markeing@dominopa.com.
              By using our site, you confirm that you accept these terms of use and that you agree to comply with them. If you do not agree to these terms, you must not use our site.</i>
            </Card.Text>

            <Card.Subtitle>You must keep your account details safe</Card.Subtitle>
            <Card.Text>
              If you choose, or you are provided with, a user identification code, password or any other piece of information as part of our security procedures, you must treat such information as confidential. You must not disclose it to any third party. We have the right to disable any user identification code or password, whether chosen by you or allocated by us, at any time, if in our reasonable opinion you have failed to comply with any of the provisions of these terms of use. If you know or suspect that anyone other than you knows your user identification code or password, you must promptly notify us at <a href="mailto:marketing@dominopa.com">marketing@dominopa.com</a> or +46 (0) 40 689 25 00
            </Card.Text>

            <Card.Subtitle>Confidential information on our site</Card.Subtitle>
            <Card.Text>
              You must keep the information contained on our site confidential and not share it with any third parties without Domino’s prior written approval. If you share any of the confidential content of the site with any third parties without Domino’s prior written approval your right to use our site will cease immediately and your access to the site may be revoked.  Without prejudice to any other rights or remedies that Domino may have, you acknowledge and agree that damages alone would not be an adequate remedy for any breach of these terms and conditions.  Accordingly, Domino shall be entitled to the remedies of injunctions, specific performance or other equitable relief for any threatened or actual breach of these terms and conditions by you.
            </Card.Text>
            <Card.Subtitle>How you may use material on our site</Card.Subtitle>
            <Card.Text>
              We are the owner or the licensee of all intellectual property rights in our site, and in the material published on it.  Those works are protected by copyright laws and treaties around the world. All such rights are reserved.

              You may print off one copy, and may download extracts, of any page(s) from our site for your personal use and you may draw the attention of others within your organisation to content posted on our site.

              You must not modify the paper or digital copies of any materials you have printed off or downloaded in any way, and you must not use any illustrations, photographs, video or audio sequences or any graphics separately from any accompanying text.

              Our status (and that of any identified contributors) as the authors of content on our site must always be acknowledged.

              You must not use any part of the content on our site for commercial purposes without obtaining a licence to do so from us or our licensors.

              If you print off, copy or download any part of our site in breach of these terms of use, your right to use our site will cease immediately and you must, at our option, return or destroy any copies of the materials you have made.
            </Card.Text>

            <Card.Subtitle>Do not rely on information on this site</Card.Subtitle>
            <Card.Text>
              The content on our site is provided for general information only. It is not intended to amount to advice on which you should rely. You must obtain professional or specialist advice before taking, or refraining from, any action on the basis of the content on our site. Although we make reasonable efforts to update the information on our site, we make no representations, warranties or guarantees, whether express or implied, that the content on our site is accurate, complete or up to date.
            </Card.Text>

          </Card.Body>
        </Card>
      </Col>
    </Row>
  </Container>
);

export default TermsAndConditions;
