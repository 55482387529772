import { useMutation, useQuery } from "@apollo/react-hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Storage } from "aws-amplify";
import BigPlayButton from "components/BigPlayButton";
import { ShareLinks } from "components/ShareLinks";
import { GQL_LIMIT } from "constants.js";
import { UPDATE_VIDEO_PLAY_COUNT } from "graphql/custom/mutations";
import { GET_VIDEO_BY_SLUG } from "graphql/custom/queries";
import groupBy from "lodash/groupBy";
import keyBy from "lodash/keyBy";
import map from "lodash/map";
import orderBy from "lodash/orderBy";
import uniqBy from "lodash/uniqBy";
import React, { useEffect, useState } from "react";
import { useGlobal } from "reactn";

import Moment from "react-moment";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useKey } from "react-use";
import {
  ControlBar,
  CurrentTimeDisplay,
  ForwardControl,
  PlaybackRateMenuButton,
  Player,
  ReplayControl,
  TimeDivider,
  VolumeMenuButton,
} from "video-react";
import { getUser } from "helpers/adminQueries";

const CategoriesToDisplay = ({ categories }) => {
  const sortedCategoryGroups = orderBy(
    keyBy(map(categories.items, "category.group"), "id"),
    ["order"],
    ["asc"]
  );
  const groupedCategories = groupBy(
    uniqBy(categories.items, "category.id"),
    "category.group.id"
  );
  const mapping = map(sortedCategoryGroups, (group) => {
    if (!group) {
      return;
    }
    const categories = orderBy(groupedCategories[group.id], ["order"], ["asc"]);
    const mappingFunction = ({ category: { label, href } }) =>
      href ? (
        <a key={`cat-${label}`} {...{ href }} target="_blank">
          {label}
        </a>
      ) : (
        <span key={`cat-${label}`}>{label}</span>
      );
    const joinMethod = (prev, curr) => [prev, ", ", curr];
    return (
      <span key={group.id} className="category-display">
        <strong>{group.label}: </strong>
        {categories.map(mappingFunction).reduce(joinMethod)}
      </span>
    );
  });

  return <p>{mapping}</p>;
};

const VideoPlayer = (props) => {
  const [videoUrl, setVideoUrl] = useState("");
  const [uploader, setUploader] = useState("");
  const history = useHistory();
  const location = useLocation();
  const { slug } = useParams();
  const [isAdmin] = useGlobal("isAdmin");

  const [updateVideoPlayCountMutation] = useMutation(UPDATE_VIDEO_PLAY_COUNT);

  const { loading, error, data } = useQuery(GET_VIDEO_BY_SLUG, {
    variables: {
      slug,
      categoriesLimit: GQL_LIMIT,
      playlistVideosLimit: GQL_LIMIT,
    },
  });

  useEffect(() => {
    const fetchData = async () => {
      if (data && data.getVideoSlug && data.getVideoSlug.video.mp4) {
        setVideoUrl(await Storage.get(data.getVideoSlug.video.mp4));
      }
    };
    fetchData();
  }, [data]);

  useEffect(() => {
    const getUploader = async () => {
      if (isAdmin && data && data.getVideoSlug && data.getVideoSlug.video.uploadedBy) {
        const gottenUser = await getUser(data.getVideoSlug.video.uploadedBy);
        if (gottenUser) {
          setUploader(
            `${gottenUser.UserAttributes.email} (${gottenUser.UserAttributes["custom:company_name"]})`
          );
        } else {
          setUploader("N/A");
        }
      }
    };

    getUploader();
  }, [isAdmin, data]);

  const closePlayer = () => {
    if (location.state && location.state.fromDashboard) {
      history.goBack();
    } else {
      history.replace("/v");
    }
  };

  // Close player on ESC
  useKey((ev) => ev.key === 'Escape', closePlayer);

  if (loading) {
    return "Loading...";
  }

  if (error) {
    console.warn(error.message);
  }

  const {
    title,
    categories,
    description,
    date,
    customer,
    country,
    playCount,
    id: videoId,
  } = data.getVideoSlug.video;

  const onClickBigPlayButtonCallback = async () => {
    await updateVideoPlayCountMutation({
      variables: {
        videoId,
        playCount: (playCount || 0) + 1,
      },
    });
  };

  return (
    <>
      <div
        className="video-details-overlay show-details"
        onClick={closePlayer}
      />

      <div className="video-details-container show-details">
        <FontAwesomeIcon
          icon="times"
          className="close-player"
          onClick={closePlayer}
        />

        <div className="video-details-content">
          <div className="video-player-container">
            <Player playsInline loop src={videoUrl}>
              <BigPlayButton
                position="center"
                onClick={onClickBigPlayButtonCallback}
              />
              <ControlBar>
                <ReplayControl seconds={10} order={1.1} />
                <ForwardControl seconds={30} order={1.2} />
                <CurrentTimeDisplay order={4.1} />
                <TimeDivider order={4.2} />
                <PlaybackRateMenuButton
                  rates={[5, 2, 1, 0.5, 0.1]}
                  order={7.1}
                />
                <VolumeMenuButton order={7.2} />
              </ControlBar>
            </Player>
            <div className="video-meta-actions-container">
              <p className="video-meta">{playCount || 0} views</p>
              <div className="video-actions">
                <ShareLinks slug={slug} />
              </div>
            </div>
          </div>
          <div className="video-details-data">
            <h1>{title}</h1>
            <p className="video-details-description">{description}</p>
            {customer && (
              <p className="video-details-extras">
                <strong>Customer:</strong>
                <br />
                {customer}
              </p>
            )}
            {country && (
              <p className="video-details-extras">
                <strong>Country:</strong>
                <br />
                {country}
              </p>
            )}
            {isAdmin && uploader && (
              <p>
                <strong>Uploaded by:</strong> {uploader}
              </p>
            )}
            {date && (
              <p className="video-details-extras">
                <strong>Recording year:</strong>{" "}
                <Moment format="LL">{date}</Moment>
              </p>
            )}
            <hr className="category-delimiter" />
            <CategoriesToDisplay categories={categories} />
          </div>
        </div>
      </div>
    </>
  );
};

export default VideoPlayer;
