/* eslint-disable */
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faArrowAltCircleDown,
  faArrowCircleDown,
  faArrowDown,
  faArrowLeft,
  faArrowUp,
  faCheck,
  faCog,
  faCopy,
  faEllipsisV,
  faFileCsv,
  faFilter,
  faInfo,
  faKey,
  faList,
  faMailBulk,
  faPencilAlt,
  faPlus,
  faPlusCircle,
  faPlusSquare,
  faSearch,
  faShareAlt,
  faShareSquare,
  faSignOutAlt,
  faSort,
  faSortAlphaDown,
  faSortAlphaDownAlt,
  faSortAmountDown,
  faSortAmountDownAlt,
  faSortDown,
  faSortNumericDown,
  faSortNumericDownAlt,
  faSortUp,
  faStar,
  faTimes,
  faTrash,
  faTrashAlt,
  faUndo,
  faUser,
  faUserCheck,
  faUserMinus,
  faUserPlus,
  faUserSlash,
  faPen,
} from "@fortawesome/free-solid-svg-icons";
import { Auth, Hub } from "aws-amplify";
import {
  Authenticator,
  AuthPiece,
  ConfirmSignIn,
  Loading,
  VerifyContact,
} from "aws-amplify-react";
import { CustomForgotPassword } from "CustomForgotPassword";
import { CustomRequireNewPassword } from "CustomRequireNewPassword";
import { CustomSignIn } from "CustomSignIn";
import TermsAndConditions from "pages/terms/TermsAndConditions";
import React, { Component, useEffect } from "react";
import ReactGA from "react-ga";
import { BrowserRouter, Redirect, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { setGlobal } from "reactn";
import "./App.scss";
import Administration from "./pages/administration";
import HomePage from "./pages/home";

library.add(
  faArrowLeft,
  faArrowUp,
  faArrowDown,
  faArrowCircleDown,
  faArrowAltCircleDown,
  faTrash,
  faTrashAlt,
  faPlusCircle,
  faPlusSquare,
  faUser,
  faUserCheck,
  faUserSlash,
  faUserPlus,
  faUserMinus,
  faTimes,
  faPencilAlt,
  faPlus,
  faCog,
  faSignOutAlt,
  faCheck,
  faUndo,
  faFilter,
  faSortAlphaDown,
  faSortAlphaDownAlt,
  faSortAmountDown,
  faSortAmountDownAlt,
  faSortNumericDown,
  faSortNumericDownAlt,
  faSort,
  faSortUp,
  faSortDown,
  faFileCsv,
  faSearch,
  faMailBulk,
  faList,
  faEllipsisV,
  faStar,
  faShareSquare,
  faShareAlt,
  faCopy,
  faKey,
  faInfo,
  faPen
);

setGlobal({
  isAdmin: false,
  auth: null,
  termsAccepted: null,
});

const listener = async (data) => {
  switch (data.payload.event) {
    case "signUp":
    case "signOut":
    case "signIn_failure":
      setGlobal({
        isAdmin: false,
        auth: null,
      });

      break;
    case "signIn":
    case "configured":
      try {
        const payload = (await Auth.currentSession()).getIdToken().payload;
        setGlobal({
          isAdmin:
            payload &&
            payload instanceof Object &&
            "cognito:groups" in payload &&
            payload["cognito:groups"].includes("Admin"),
          auth: payload,
        });
        localStorage.setItem(
          "terms-accepted",
          payload["custom:terms_accepted"] === "true"
        );
      } catch (e) {
        setGlobal({
          isAdmin: false,
          auth: null,
        });
      }
      break;
  }
};
Hub.listen("auth", listener);

const Router = () => {
  return (
    <BrowserRouter>
      <div>
        <Route exact path="/">
          <Redirect to="/v" />
        </Route>

        <Route path="/v" component={withTracker(HomePage)} />
        <Route path="/administration" component={withTracker(Administration)} />
        <Route path="/terms" component={withTracker(TermsAndConditions)} />
      </div>
    </BrowserRouter>
  );
};

const withTracker = (WrappedComponent, options = {}) => {
  const trackPage = (page) => {
    ReactGA.set({
      page,
      ...options,
    });
    ReactGA.pageview(page);
  };

  return (props) => {
    useEffect(
      () => trackPage(props.location.pathname),
      [props.location.pathname]
    );

    return <WrappedComponent {...props} />;
  };
};

class App extends AuthPiece {
  constructor(props) {
    super(props);
    this._validAuthStates = ["signedIn"];
  }

  showComponent(theme) {
    return (
      <>
        <ToastContainer />
        <Router />
      </>
    );
  }
}

class AppWithAuth extends Component {
  render() {
    return (
      <Authenticator usernameAttributes="email" hideDefault={true}>
        <CustomSignIn />
        <ConfirmSignIn />
        <VerifyContact />
        <CustomRequireNewPassword />
        <CustomForgotPassword />
        <Loading />
        <App />
      </Authenticator>
    );
  }
}

export default AppWithAuth;
