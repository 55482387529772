import confirm from "components/ConfirmationDialog";
import { GQL_LIMIT } from "constants.js";
import { useFormik } from "formik";
import {
  DELETE_PLAYLIST,
  DELETE_PLAYLIST_VIDEO,
  UPDATE_PLAYLIST,
} from "graphql/custom/mutations";
import {
  LIST_PLAYLIST_VIDEOS_BY_PLAYLIST,
  LIST_PLAYLIST_VIDEOS_BY_VIDEO,
} from "graphql/custom/queries";
import PropTypes from "prop-types";
import React from "react";
import { useMutation } from "react-apollo";
import {
  Button,
  ButtonGroup,
  ButtonToolbar,
  Card,
  Form,
  FormControl,
} from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { useSet } from "react-use";
import { PlaylistVideo } from "./PlaylistVideo";

export const PlaylistCard = ({
  playlist: { title, description, id },
  playlistVideos,
  onClose,
}) => {
  const history = useHistory();
  const [updatePlaylist] = useMutation(UPDATE_PLAYLIST);
  const [deletePlaylist] = useMutation(DELETE_PLAYLIST, {
    refetchQueries: ["ListPlaylists"],
  });
  const [deletePlaylistVideo] = useMutation(DELETE_PLAYLIST_VIDEO, {
    refetchQueries: ["ListPlaylistVideos"],
  });
  const [toDelete, toDeleteOps] = useSet();
  const formik = useFormik({
    initialValues: {
      title: title,
      description: description,
    },
    onSubmit: async values => {
      await Promise.all([
        ...[...toDelete].map(playlistVideo =>
          deletePlaylistVideo({
            variables: { input: { id:playlistVideo.id } },
            awaitRefetchQueries: false,
            refetchQueries: [
              {
                query: LIST_PLAYLIST_VIDEOS_BY_PLAYLIST,
                variables: { playlistID: playlistVideo.playlistID, limit: GQL_LIMIT },
              },
              {
                query: LIST_PLAYLIST_VIDEOS_BY_VIDEO,
                variables: { videoID: playlistVideo.videoID, limit: GQL_LIMIT },
              },
            ],
          })
        ),
        updatePlaylist({ variables: { input: { id, ...values } } }),
      ]);
      onClose();
      history.push(`/v/playlist/${id}`);
    },
    onReset: () => onClose(),
  });
  const onDeletePlaylist = async () => {
    await Promise.all([
      ...playlistVideos.map(playlistVideo =>
        deletePlaylistVideo({ variables: { input: { id: playlistVideo.id } } })
      ),
      deletePlaylist({ variables: { input: { id } } }),
    ]);
    onClose();
    history.replace("/v");
  };
  return (
    <Card>
      <Form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
        <Card.Title>
        { formik.values.title !== "Favourites" ? 
          <FormControl
            type="text"
            name="title"
            onChange={formik.handleChange}
            value={formik.values.title}
            className="playlist-title-input"
          />
          : formik.values.title }
        </Card.Title>
        <Card.Subtitle>{description}</Card.Subtitle>

        <Card.Body>
          <TransitionGroup className="edit-playlist">
            {playlistVideos
              .filter(playlistVideo => !toDeleteOps.has(playlistVideo))
              .map(playlistVideo => {
                return (
                  <CSSTransition
                    key={`playlistVideo-${playlistVideo.id}`}
                    timeout={500}
                    classNames="playlistVideo"
                  >
                    <PlaylistVideo
                      playlistVideo={playlistVideo}
                      onDelete={toDeleteOps.add}
                      isDeleted={toDeleteOps.has(playlistVideo)}
                    />
                  </CSSTransition>
                );
              })}
          </TransitionGroup>
        </Card.Body>
        <Card.Footer>
          <ButtonToolbar className="justify-content-between">
            <ButtonGroup>
              <Button type="reset" variant="basic" className="playlist-close-button">Close</Button>
              <Button type="submit" className="playlist-save-button">Save changes</Button>
            </ButtonGroup>
            <ButtonGroup>
              <Button
                className="playlist-delete-button"
                variant="danger"
                onClick={() =>
                  confirm(
                    "Are you sure you want to delete this playlist? This cannot be undone."
                  ).then(onDeletePlaylist, () => {})
                }
              >
                Delete playlist
              </Button>
            </ButtonGroup>
          </ButtonToolbar>
        </Card.Footer>
      </Form>
    </Card>
  );
};

PlaylistCard.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  playlistVideos: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
};
