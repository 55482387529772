import { SignIn } from "aws-amplify-react";
import React from "react";
import { Button } from "react-bootstrap";

export class CustomSignIn extends SignIn {
  constructor(props) {
    super(props);
    this._validAuthStates = ["signIn", "signedOut", "signedUp"];
  }

  showComponent(theme) {
    return (
      <div className="login">
        <div className="blurred-bg"></div>
        <div className="login-form-container">
          <form className="form-signin" onSubmit={this.signIn}>
            <div className="text-center mb-4">
              <div className="mb-4 login-logo"></div>
              <h1 className="h1 mb-3">DPAplay</h1>
              <h2 className="h2 mb-3">M-Series Video Library</h2>
            </div>
            <div className="form-label-group">
              <input
                type="email"
                id="inputEmail"
                name="email"
                className="form-control"
                placeholder="Email address"
                onChange={this.handleInputChange}
                required
                autoFocus
              />
              <label htmlFor="inputEmail">Email address</label>
            </div>

            <div className="form-label-group">
              <input
                type="password"
                name="password"
                id="inputPassword"
                className="form-control"
                placeholder="Password"
                onChange={this.handleInputChange}
                required
              />
              <label htmlFor="inputPassword">Password</label>
            </div>

            <p className="text-muted login-forgot-pw">
              Forgot your password?{" "}
              <Button
                variant="link"
                className=""
                onClick={() => super.changeState("forgotPassword")}
              >
                Reset Password
              </Button>
            </p>

            <button
              className="btn btn-lg btn-primary btn-block btn-login"
              type="submit"
              onClick={() => super.signIn()}
            >
              Sign in
            </button>

            <p className="text-light text-center"><br/>No account? Contact <a href="mailto:marketing@dominopa.com">marketing@dominopa.com</a></p>
          </form>
        </div>
      </div>
    );
  }
}
