import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Storage } from "aws-amplify";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import { Button, Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";

export const PlaylistVideo = ({
  playlistVideo,
  onDelete,
  onMoveUp,
  onMoveDown,
  isDeleted,
}) => {
  const [thumbUrl, setThumbUrl] = useState(null);

  useEffect(() => {
    if (!playlistVideo.video || !playlistVideo.video.thumb) {
      return;
    }
    Storage.get(playlistVideo.video.thumb).then(setThumbUrl);
  }, [playlistVideo.video]);

  const onDeleteCb = useCallback(
    async e => {
      // await deletePlaylistVideo({
      //   variables: { input: { id: playlistVideo.id } },
      // });
      onDelete(playlistVideo);
    },
    [onDelete, playlistVideo]
  );
  if (!playlistVideo.video) {
    return "Error! Missing video for entry " + playlistVideo.id;
  }
  const { title, id } = playlistVideo.video;
  const videoThumbStyle = {
    backgroundImage: 'url('+thumbUrl+')',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    backgroundColor: 'transparent',
  }

  return (
    <Row className="playlist-video-entry">
      <Col sm={2} className="playlist-video-thumb" style={videoThumbStyle}>
        
      </Col>
      <Col className="text-muted playlist-video-title" sm={9}>
        <Link to={`/v/${id}`}>{title}</Link>
      </Col>
      <Col sm={1} className="playlist-video-actions">
        <OverlayTrigger
          overlay={
            <Tooltip>
              Remove <strong>{title}</strong> from playlist
            </Tooltip>
          }
        >
          <Button variant="link" onClick={onDeleteCb}>
            <FontAwesomeIcon icon="trash-alt" />
          </Button>
        </OverlayTrigger>
      </Col>
    </Row>
  );
};

PlaylistVideo.proptype = {
  playlistVideo: PropTypes.shape({
    id: PropTypes.string.isRequired,
    video: PropTypes.shape({
      title: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      thumb: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};
