import PropTypes from "prop-types";
import React from "react";
import { Button, Modal } from "react-bootstrap";
import { confirmable } from "react-confirm";

const ConfirmationDialog = ({
  show,
  proceed,
  dismiss,
  cancel,
  confirmation,
  options,
  titleText,
  cancelText,
  confirmText,
}) => (
  <Modal show={show} className="ConfirmationDialog">
    <Modal.Header closeButton onHide={dismiss}>
      <Modal.Title>{titleText}</Modal.Title>
    </Modal.Header>

    <Modal.Body>
      <p>{confirmation}</p>
    </Modal.Body>

    <Modal.Footer>
      <Button variant="secondary" onClick={cancel}>
        {cancelText}
      </Button>
      <Button variant="primary" onClick={proceed}>
        {confirmText}
      </Button>
    </Modal.Footer>
  </Modal>
);

ConfirmationDialog.propTypes = {
  show: PropTypes.bool, // from confirmable. indicates if the dialog is shown or not.
  proceed: PropTypes.func, // from confirmable. call to close the dialog with promise resolved.
  cancel: PropTypes.func, // from confirmable. call to close the dialog with promise rejected.
  dismiss: PropTypes.func, // from confirmable. call to only close the dialog.
  confirmation: PropTypes.node, // arguments of your confirm function
  options: PropTypes.object, // arguments of your confirm function
  titleText: PropTypes.node,
  confirmText: PropTypes.node,
  cancelText: PropTypes.node,
};

ConfirmationDialog.defaultProps = {
  titleText: "Confirm",
  cancelText: "Cancel",
  confirmText: "Confirm",
};

// confirmable HOC pass props `show`, `dismiss`, `cancel` and `proceed` to your component.
export default confirmable(ConfirmationDialog);
