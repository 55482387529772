import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
export const SortButton = ({ order, onClick }) => {
  let icon;
  switch (order) {
    case "asc":
      icon = "sort-down";
      break;
    case "desc":
      icon = "sort-up";
      break;
    default:
      icon = "sort";
      break;
  }
  return (
    <FontAwesomeIcon className="sort-button" icon={icon} onClick={onClick} />
  );
};
