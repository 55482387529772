import gql from "graphql-tag";

export const videoFieldsFragment = gql`
  fragment videoFields on video {
    id
    owner
    title
    description
    favorite
    tag
    date
    reference
    customer
    country
    approved
    approvedAt
    public
    ready
    thumb
    mp4
    createdAt
    updatedAt
    uploadedBy
    playCount
    videoSlugId
    __typename
  }
`;

export const videoSlugFieldsFragment = gql`
  fragment videoSlugFields on videoSlug {
    slug
    slugBase
    createdAt
    updatedAt
    videoID
    owner
    __typename
  }
`;

export const categoryFieldsFragment = gql`
  fragment categoryFields on category {
    id
    label
    href
    order
    __typename
  }
`;

export const categoryGroupFieldsFragment = gql`
  fragment categoryGroupFields on categoryGroup {
    id
    label
    order
    __typename
  }
`;
export const playlistFieldsFragment = gql`
  fragment playlistFields on playlist {
    id
    title
    description
    __typename
  }
`;

export const playlistVideoFieldsFragment = gql`
  fragment playlistVideoFields on playlistVideo {
    id
    playlistID
    videoID
    __typename
  }
`;
export const videoCategoriesFieldsFragment = gql`
  fragment videoCategoriesFields on videoCategory {
    id
    __typename
    category {
      ...categoryFields

      group {
        ...categoryGroupFields
      }
    }
  }
  ${categoryGroupFieldsFragment}
  ${categoryFieldsFragment}
`;
