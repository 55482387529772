import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import React from "react";
import Select from "react-select";

const iconMap = {
  createdAt: "amount",
  date: "amount",
  playCount: "numeric",
  title: "alpha",
};
const labelMap = {
  createdAt: "Uploaded date",
  date: "Recording year",
  playCount: "Play count",
  title: "Title",
};
const options = [
  {
    order: "ASC",
    field: "date",
  },
  {
    order: "DESC",
    field: "date",
  },
  {
    order: "ASC",
    field: "createdAt",
  },
  {
    order: "DESC",
    field: "createdAt",
  },
  {
    order: "ASC",
    field: "title",
  },
  {
    order: "DESC",
    field: "title",
  },
  {
    order: "ASC",
    field: "playCount",
  },
  {
    order: "DESC",
    field: "playCount",
  },
];
const SortOrder = ({ sortOrder, setSortOrder, onChange = () => {} }) => {
  return (
    <Select
      classNamne="archive-sort-select"
      value={sortOrder}
      options={options}
      onChange={({ order, field }) => {
        setSortOrder({ order, field });
        onChange();
      }}
      getOptionValue={o => `${o.field}:${o.order}`}
      getOptionLabel={o => (
        <>
          <FontAwesomeIcon
            icon={`sort-${iconMap[o.field]}-down${
              o.order === "DESC" ? "-alt" : ""
            }`}
          />
          {labelMap[o.field]}{" "}
          {
            (o.field === "date" || o.field === "createdAt" ) ? 
              o.order === "ASC"  ? "(oldest first)" : "(newest first)" :

            o.field === "title" ? 
              o.order === "ASC" ? "(A - Z)" : "(Z - A)" :
          
            o.field === "playCount" ?
              o.order === "ASC" ? "(least viewed)" : "(most viewed)" :

            ""
          }
        </>
      )}
    />
  );
};
SortOrder.propTypes = {
  sortOrder: PropTypes.object,
  setSortOrder: PropTypes.func.isRequired,
  onChange: PropTypes.func,
};

export default SortOrder;
