import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { FAVOURITES_PLAYLIST_TITLE, GQL_LIMIT } from "constants.js";
import { LIST_PLAYLISTS } from "graphql/custom/queries";
import React from "react";
import { useQuery } from "react-apollo";
import { ListGroup, ListGroupItem } from "react-bootstrap";
import { Link } from "react-router-dom";
const PlaylistSelector = ({ toggle }) => {
  const playlists = useQuery(LIST_PLAYLISTS, {
    variables: { limit: GQL_LIMIT },
  });

  if (playlists.loading) {
    return "Loading...";
  }

  if (playlists.error) {
    console.warn(playlists.error.message);
  }

  return (
    <ListGroup variant="flush" className="playlist-selector">
      { playlists.data.listPlaylists.items.length===0 ? <p className="playslist-no-lists-placeholder">There are currently no playlists to show. Add a movie to your Favourites by "Starring" it, or to a new custom playlist first.</p> :

       playlists.data.listPlaylists.items
        .sort((a, b) => {
          if (a.title === FAVOURITES_PLAYLIST_TITLE) {
            return -1;
          }
          if (b.title === FAVOURITES_PLAYLIST_TITLE) {
            return 1;
          }
          return (a.title || "").localeCompare(b.title || "");
        })
        .map(playlist => {
          return (
            <ListGroupItem
              className={classNames({
                "favourite-list-entry":
                  playlist.title === FAVOURITES_PLAYLIST_TITLE,
              })}
              key={`playlist-${playlist.id}`}
            >
              {playlist.title === FAVOURITES_PLAYLIST_TITLE && (
                <FontAwesomeIcon icon="star" />
              )}
              <Link
                variant="link"
                onClick={() => toggle(false)}
                className="playlist-link"
                to={{
                  pathname: `/v/playlist/${playlist.id}`,
                  state: { fromDashboard: true },
                }}
              >
                {playlist.title}
              </Link>
              <Link
                variant="link"
                className="playlist-edit"
                onClick={() => toggle(false)}
                to={`/v/playlist/${playlist.id}/edit`}
              >
                <FontAwesomeIcon icon="pencil-alt" />
              </Link>
            </ListGroupItem>
          );
        })
      }
    </ListGroup>
  );
};

PlaylistSelector.propTypes = {};

PlaylistSelector.defaultProps = {};

export default PlaylistSelector;
